import React from 'react'
import InfoIcon from '../Icon/InfoIcon'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const InfoComponent = ({ size, content }) => {

    const renderTooltip = (porps) => (
        <Tooltip  id="button-tooltip" className='  ' {...porps} >{content}</Tooltip>
    );

    return (
        <div className=' ' >
            <OverlayTrigger className=" "
                placement="top" delay={{ show: 150, hide: 200 }}
                overlay={renderTooltip} >
                <button>
                    <InfoIcon size={size} />
                </button>
            </OverlayTrigger>

        </div>
    )
}

export default InfoComponent