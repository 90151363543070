import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { port } from '../App'
import { toast } from 'react-toastify'
import EyeIcon from '../Icon/EyeIcon'
import ClosedEyeIcon from '../Icon/ClosedEyeIcon'

const Login = () => {
    let navigate = useNavigate()
    let [loading, setloading] = useState(false)
    let [passwordShow, setShowPassword] = useState(true)
    let [errorMsg, setErrorMsg] = useState('')
    let [signinObj, setSignObj] = useState({
        email: '',
        password: ''
    })
    let handleChange = (e) => {
        let { name, value } = e.target
        setSignObj((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    let login = (e) => {
        e.preventDefault()

        if (signinObj.email == '' || signinObj.password == '') {
            return toast.warning('Enter the fields')
        }
        setErrorMsg('')
        setloading(true)
        axios.post(`${port}/api/userLogin`, signinObj).then((response) => {
            toast.success("Welcome for the day")
            sessionStorage.setItem('gebba_user', response.data._id)
            sessionStorage.setItem('status', response.data.emp_type)

            setTimeout(() => {
                setloading(false)
            }, 2000);
            navigate('/')
        }).catch((error) => {
            console.log(error);

            toast.error("Error Acquired")
            if (error.response && error.response.data)
                setErrorMsg(error.response.data)
            console.log(error);
        })
        setTimeout(() => {
            setloading(false)
        }, 2000);
    }
    return (
        <div>
            <main className='min-h-[100vh] singinbg flex ' >
                <img src={require('../Assests/GebaLogo.webp')} alt="Logo" className='absolute  top-10 left-10 ' />
                <section className='m-auto col-11 col-sm-8 bg-white col-lg-5 col-xl-4 p-2 
                px-5 rounded-xl border-2  ' >

                    <form action="" onSubmit={login} className='text-start ' >
                        <h4 className='text-center text-3xl my-3' >Sign in </h4>
                        <div className='text-start my-3 ' >
                            <p className='mb-1 fw-semibold ' >  Email or Employee Id or Phone </p>
                            <input type="text" value={signinObj.email} name='email' onChange={handleChange}
                                className='mt-3 w-full p-2 rounded-lg outline-none border-2  ' />
                        </div>
                        <div className='my-3 ' >
                            <article className='flex justify-between gap-3   ' >
                                <p className='mb-1 fw-semibold ' >Password </p>

                            </article>
                            <div className='mt-3 w-full p-2 rounded-lg flex items-center justify-between 
                             outline-none border-2 ' >
                                <input type={`${passwordShow ? 'password' : 'text'}`}
                                    value={signinObj.password} name='password' onChange={handleChange}
                                    className=' outline-none w-full ' />
                                <button type='button' onClick={() => setShowPassword(!passwordShow)} className=' ' >
                                    {passwordShow ? <EyeIcon size={20} /> : <ClosedEyeIcon size={20} />}
                                </button>
                            </div>

                        </div>
                        <p className='h-[25px] text-red-600 text-center ' >{errorMsg} </p>
                        <button type='submit' disabled={loading} className='w-full my-3  rounded-full text-white btngrd2 p-2' >
                            {loading ? "Loading..." : "Log In"}
                        </button>
                    </form>
                    <article className='flex justify-between my-2 ' >
                        <button onClick={() => navigate('/forgotPassword')} className='my-3 text-center ' >
                            Forgot Password ?
                        </button>
                        <button onClick={() => navigate('/signup')} className='' >
                            Sign Up
                        </button>
                    </article>
                </section>
            </main>
        </div>
    )
}

export default Login