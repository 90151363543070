import React, { useContext, useEffect, useState } from 'react'
import { Store } from '../../Context/Storage'
import axios from 'axios'
import StageTable from '../../Components/Tables/StageTable'
import { port } from '../../App'

const EmployeeDashBoard = () => {
    let { getUser, user,setDasActive } = useContext(Store)
    let [stages, setStages] = useState()
    useEffect(() => {
        getStages()
        setDasActive('assign')
        if (!user)
            getUser()
    }, [])
    let getStages = () => {
        axios.get(`${port}/api/stage?empId=${(sessionStorage.getItem('gebba_user'))}&status=open`).then((response) => {
            console.log(response.data, 'stages');
            setStages(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }

    return (
        <div>
            <h4 className='text-slate-700 my-3 ' > Stages </h4>

            <StageTable data={stages} />
        </div>
    )
}

export default EmployeeDashBoard