import React from 'react'

const LogOutIcon = ({ size }) => {
    return (
        <svg width={size ? size : "24"} height={size ? size : "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2_981)">
                <path d="M11 7L9.6 8.4L12.2 11H2V13H12.2L9.6 15.6L11 17L16 12L11 7ZM20 19H12V21H20C21.1 21 22 20.1 22 19V5C22 3.9 21.1 3 20 3H12V5H20V19Z" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_2_981">
                    <rect width={size ? size : "24"} height={size ? size : "24"} fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default LogOutIcon