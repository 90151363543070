import React, { useContext, useEffect, useState } from 'react'
import { Store } from '../../Context/Storage'
import { useNavigate } from 'react-router'
import SearchIcon from '../../Icon/SearchIcon'
import InfoComponent from '../InfoComponent'
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import ExcelDownload from '../ExcelDownload'

const StageTable = ({ align, data, size }) => {
    let { readableTime, getProperDate } = useContext(Store)
    let [filteredData, setFilteredData] = useState()
    let [loading, setLoading] = useState(false)
    let navigate = useNavigate()
    let [filterObj, setFilterObj] = useState({
        from: '',
        to: ''
    })
    let handleFilterObj = (e) => {
        let { name, value } = e.target
        if (name == 'to' && filterObj.from && value < filterObj.from)
            value = filterObj.from
        if (name == 'from' && filterObj.to && value > filterObj.to)
            value = filterObj.to
        setFilterObj((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    useEffect(() => {
        if (data)
            setFilteredData(data)
    }, [data])
    let filterDataFuc = (e) => {
        let value = e.target.value.toLowerCase();
        console.log(value);
        setLoading(true)

        let filterDataByDate = data.filter((obj) => {
            let fromCondition = filterObj.from ? filterObj.from <= (obj.orderId && obj.orderId.created_date && getProperDate(obj.orderId.created_date)) : true;
            let toCondition = filterObj.to ? filterObj.to >= (obj.orderId && obj.orderId.created_date && getProperDate(obj.orderId.created_date)) : true;
            return fromCondition && toCondition;
        });
        console.log(filterDataByDate, "date", filterObj.to);


        if (value) {
            let filteringData = filterDataByDate.filter((obj) =>
                (obj.confimation_no && obj.confimation_no.toLowerCase().indexOf(value) !== -1) ||
                (obj.stage_name?.toLowerCase().indexOf(value) !== -1) ||
                (obj.orderId && obj.orderId.orderId && obj.orderId.orderId.toLowerCase().indexOf(value) !== -1) ||
                (obj.materials_prepared_by && obj.materials_prepared_by.find((obj2) => obj2.material.toLowerCase().indexOf(value) != -1))
            );
            console.log(filteringData, 'filter');
            setFilteredData(filteringData);
        } else {
            setFilteredData(filterDataByDate);
        }
        setLoading(false)

    };
    const renderTooltip = (porps) => (
        <Tooltip id="button-tooltip" className='  ' {...porps} > Search the stages by ,StageId , Stage Name , OrderID , Materials added  </Tooltip>
    );
    return (
        <div>
            <section className={` ${align ? align : ''} my-2 flex flex-wrap gap-3 `} >
                <OverlayTrigger className=" "
                    placement="top" delay={{ show: 150, hide: 200 }}
                    overlay={renderTooltip}  >

                    <div className=' border-2 bg-white rounded w-fit flex gap-2 items-center px-2 relative ' >
                        <input type="text" onChange={filterDataFuc} placeholder='Search...' className='p-2 rounded outline-none ' />
                        <SearchIcon />
                        {/* <span className='absolute -right-2 -top-5 ' >
                            <InfoComponent size={12} content="Search the stages by ,StageId , Stage Name , OrderID , Materials added " />
                        </span> */}
                    </div>
                </OverlayTrigger>
                <div className=' border-2 bg-white rounded w-fit flex gap-2 items-center px-2 ' >
                    From :
                    <input type="date" value={filterObj.from} name='from' onChange={handleFilterObj} className='outline-none ' />
                </div>
                <div className=' border-2 bg-white rounded w-fit flex gap-2 items-center px-2 ' >
                    To :
                    <input type="date" value={filterObj.to} name='to' onChange={handleFilterObj} className='outline-none ' />
                </div>
                <button onClick={filterDataFuc}
                    className='p-2 rounded border-2 border-green-300 w-32 bg-green-500 text-white ms-auto ' >
                    Search
                </button>


            </section>
            <main className={` table-responsive ${size ? size : "h-[60vh]"}
             overflow-y-scroll rounded w-full tablebg px-0 `} >
                <table className='w-full ' >
                    <tr className='sticky top-0  bg-blue-800 text-white    ' >
                        <th> SI NO </th>
                        <th>Stage Id </th>
                        <th>Order Id </th>
                        <th>Stage name </th>
                        <th>Description </th>
                        <th> Created Date </th>
                        <th>Good Quantity </th>
                        {/* <th>Status </th> */}
                    </tr>
                    {
                        loading ?
                            <tr className='h-[50vh]   w-full ' >
                                <td className=' '  ></td>
                                <td> </td>
                                <td></td>
                                <td  > <Spinner className='m-auto ' /></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr> : <>
                                {
                                    filteredData && [...filteredData].reverse().map((obj, index) => (
                                        <tr onClick={() => navigate(`/stage/${obj._id}`)}
                                            className={`text-sm ${index % 2 == 0 ? ' ' : ' rowbg '} hover:bg-blue-50 cursor-pointer `} >
                                            <td className='colbg ' >{index + 1} </td>
                                            {console.log(obj, 'stagedetails')
                                            }
                                            <td >{obj.confimation_no} </td>
                                            <td className='colbg '>{obj.orderId && obj.orderId.orderId} </td>
                                            <td>{obj.stage_name} </td>
                                            <td className='max-w-[170px] text-wrap break-words colbg ' >{obj.stage_description} </td>
                                            <td  > {obj.orderId && obj.orderId && obj.orderId.created_date && readableTime(obj.orderId.created_date)} </td>

                                            <td className='colbg text-center' >{obj.goodQuality} </td>
                                            {/* <td>{obj.status} </td> */}
                                        </tr>
                                    ))
                                } </>
                    }

                </table>
            </main>
            {
                filteredData && <div className='my-2 ' >
                    <ExcelDownload data={[...filteredData].map((obj) => ({
                        "Order Id ": obj.orderId && obj.orderId.orderId,
                        "Confimation No ": obj.confimation_no,
                        "Stage Name ": obj.stage_name,
                        "Good Quantity ": obj.goodQuality,
                        "Comments": obj.coments,
                        "Assigned Employee": obj.assigned_to && obj.assigned_to.map((obj2) => obj2.emp_name).join(' , '),
                        "Materials Scanned": obj.materials_prepared_by && obj.materials_prepared_by.map((obj2) => `${obj2.material}${obj2.part_number ? `(${obj2.part_number})` : ''} `).join(' , ')

                    }))} name="StageReports" />
                </div>
            }
        </div>
    )
}

export default StageTable