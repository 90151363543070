import React, { useContext, useEffect, useState } from 'react'
import { Store } from '../../Context/Storage'
import OrderTables from '../../Components/Tables/OrderTables'

const OpenOrders = () => {
  let { dasactive, setDasActive, getAllOrders, allorders } = useContext(Store)
  useEffect(() => {
    setDasActive('open')
    getAllOrders()
  }, [])
  let [filteredData, setFilteredData] = useState()
  useEffect(() => {
    if (allorders) {
      setFilteredData(allorders.filter((obj) =>obj.status=='open'))
    }
  }, [allorders])
  return (
    <div className=' ' >
      <h4 className='poppins text-slate-800 text-lg' >Open Orders  </h4>

      <OrderTables data={filteredData} />
    </div>
  )
}

export default OpenOrders