import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { port } from '../App'
import { toast } from 'react-toastify'
import CreateEmployee from '../Components/Employee/CreateEmployee'

const SignUp = () => {
    let navigate = useNavigate()
    let [errormsg, setErrorMsg] = useState()
    let [loading, setloading] = useState(false)
    let [empObj, setEmpObj] = useState({
        emp_type: '',
        emp_name: '',
        email: '',
        phone: '',
        password: '',
        otp: '',
        profile_pic: null
    })
    let [recievedOtp, setReceivedOtp] = useState()
    let handleEmpObj = (e) => {
        let { name, value, files } = e.target
        if (name == 'profile_pic') {
            value = files[0]
        }
        setEmpObj((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    let [passwordShow, setShowPassword] = useState(false)
    let signup = () => {
        alert("Calling")
    }
    let reset = () => {
        setEmpObj({
            emp_type: '',
            emp_name: '',
            email: '',
            phone: '',
            password: '',
            otp: '',
            profile_pic: null
        })
    }
    let createEmployee = () => {
        setErrorMsg('')
        let formData = new FormData()
        formData.append('emp_type', empObj.emp_type);
        formData.append('emp_name', empObj.emp_name);
        formData.append('email', empObj.email);
        formData.append('phone', empObj.phone);
        formData.append('password', empObj.password);
        // formData.append('otp', empObj.otp);
        // Append the profile picture if it's selected
        if (empObj.profile_pic) {
            formData.append('profile', empObj.profile_pic);
        }
        setloading(true)
        axios.post(`${port}/api/user`, formData).then((response) => {
            console.log(response.data);
            toast.success("User added")
            setloading(false)
            reset()
        }).catch((error) => {
            console.log(error);
            setloading(false)
            toast.error('error acquired')
            if (typeof error.response.data == "string")
                setErrorMsg(error.response.data)
        })
    }
    return (
        <div className='singinbg ' >
            <img src={require('../Assests/GebaLogo.webp')} alt="Logo" className='absolute  top-10 left-10 ' />

            <main className='min-h-[100vh]  items-center row mx-auto container flex ' >
                <CreateEmployee />
            </main>

        </div>
    )
}

export default SignUp