import React, { useContext, useEffect, useState } from 'react'
import { Store } from '../../Context/Storage'
import ScannerButton from '../../Components/ScannerButton'
import InputComponent from '../../Components/InputComponent'
import PlusIcon from '../../Icon/PlusIcon'
import Dustbin from '../../Icon/Dustbin'
import axios from 'axios'
import { port } from '../../App'
import { toast } from 'react-toastify'
import { useParams } from 'react-router'
import MaterialsPreparedModel from '../../Components/MaterialsPreparedModel'
import InfoComponent from '../../Components/InfoComponent'
import { Modal } from 'react-bootstrap'

const OrderCreation = () => {
  let { setactive, getAllEmp, allemp, getUser, allStages, user, encryptWord, decryptWord } = useContext(Store)
  let { id } = useParams()
  let emp_status = sessionStorage.getItem('status')
  let [loading, setloading] = useState(false)
  let [confirmation, setconfirmation] = useState(false)
  let [closingModal, setClosingModal] = useState()
  useEffect(() => {
    let hellow = encryptWord('admin')
    console.log(hellow, 'stage');
    let decrypt = decryptWord(hellow)
    console.log(decrypt, 'stage2');
  }, [])
  let [orderObj, setOrderObj] = useState({
    orderId: '',
    material_type: '',
    material_no: '',
    description: '',
    quantity: '',
    closing_statement: null,
    incharge: [
      {
        employee: sessionStorage.getItem('gebba_user'),
        active: true
      }
    ]
  })
  let [stages, setStages] = useState([{
    confimation_no: '',
    stage_name: '',
    stage_description: '',
    badQuantity: null,
    goodQuality: null,
    assigned_to: []
  }])
  let [showMaterial, setShowMaterials] = useState()

  // Reset function
  let reset = () => {
    setOrderObj({
      orderId: '',
      material_type: '',
      description: '',
      quantity: '',
      material_no: '',
      closing_statement: '',
      incharge: [
        {
          employee: sessionStorage.getItem('gebba_user'),
          active: true
        }
      ]
    })
    setStages([{
      confimation_no: '',
      stage_name: '',
      stage_description: '',
      badQuantity: null,
      goodQuality: null,
      assigned_to: []
    }])
  }


  useEffect(() => {
    if (!user)
      getUser()
  }, [])
  let handleChangeStages = (e, index) => {
    let { name, value } = e.target

    let updatedStages = [...stages]
    if (name == 'assigned_to') {
      let assignArry = updatedStages[index].assigned_to;

      if (!assignArry.find((obj) => obj._id == e.target.value)) {
        value = allemp.find((obj) => obj._id == e.target.value); // Correct the value assignment
        assignArry.push(value);
      }
      else {
        assignArry = assignArry.filter((obj) => obj._id != e.target.value)
      }

      updatedStages[index] = {
        ...updatedStages[index],
        [name]: assignArry
      };
    }
    else
      updatedStages[index] = {
        ...updatedStages[index],
        [name]: value
      }
    setStages(updatedStages)
  }
  let handleChangeOrder = (e, index) => {
    let { name, value } = e.target
    setOrderObj((prev) => ({
      ...prev,
      [name]: value
    }))
  }
  let removeEmployeeFromStage = (index, id) => {
    let updatedStage = [...stages]
    let assignArry = updatedStage[index].assigned_to
    updatedStage[index] = {
      ...updatedStage[index],
      assigned_to: assignArry.filter((obj) => obj._id != id)
    }
    setStages(updatedStage)
  }
  useEffect(() => {
    setactive('order')
    getAllEmp()
  }, [])
  let createOrder = () => {
    setloading(true)
    console.log({
      ...orderObj,
      stages: [...stages]
    }, 'orders');
    // return
    axios.post(`${port}/api/order`, {
      ...orderObj,
      stages: [...stages]
    }).then((response) => {
      toast.success('Order added ')
      reset()
      setloading(false)
    }).catch((error) => {
      console.log(error);
      toast.error('Error occured')
      setloading(false)

    })
  }
  let getParticularOrder = () => {
    // alert('here in particular')
    axios.get(`${port}/api/order/${id}`).then((response) => {
      setOrderObj(response.data);
      console.log(response.data, "parorder");

      if (response.data.Stages)
        setStages(response.data.Stages)
    }).catch((error) => {
      console.log(error);

    })
  }
  let updateOrder = (status) => {
    if (status == 'close' && !orderObj.closing_statement) {
      toast.warning('Closing Statement required ')
      setloading(false)
      return
    }
    // return
    if (status) {
      setloading(status)
    }
    else
      setloading(true)

    console.log({
      ...orderObj,
      status: status ? status : orderObj.status,
      stages: [...stages].map((obj) =>
        ({ ...obj, badQuantity: Number(obj.badQuantity) }))
    }, 'update');
    if (status == 'close') {
      orderObj.closed_by = sessionStorage.getItem('gebba_user')
      orderObj.closed_date = new Date()

    }
    if (status == 'open') {
      orderObj.closed_by = null
      orderObj.closed_date = null
    }
    axios.put(`${port}/api/order/${id}`, {
      ...orderObj,
      status: status ? status : orderObj.status,
      stages: [...stages]
    }).then((response) => {
      toast.success('Order updated successfully')
      console.log(response.data);
      setloading(false)
      setClosingModal(false)
      getParticularOrder()
    }).catch((error) => {
      console.log(error);
      setloading(false)

      toast.error('Error occured')

    })
  }
  useEffect(() => {
    if (id)
      getParticularOrder()
    else {
      reset()
    }
  }, [id])
  return (
    <div>
      <h5 className='text-slate-600 text-xl text-center fw-semibold ' > Order {id ? 'Update' : "Creation"} </h5>
      <h6 className='text-slate-600 text-xl ' >Order Details </h6>

      {user && console.log(user.emp_type)}

      <main className='row my-2 container mx-auto bg-white rounded py-2 ' >
        <section className='col-sm-6 flex flex-col gap-2 ' >
          <InputComponent size='col-12' mandatory value={orderObj.orderId} onchange={handleChangeOrder} name="orderId"
            setobj={setOrderObj} scan label="Order Id :" />
          <InputComponent size='col-12' value={orderObj.material_type} onchange={handleChangeOrder} name="material_type"
            label="Material type :" />
          <section className={` my-2 `} >
            <article className='flex w-full justify-between border-slate-300 border-2 px-2 h-fit p-[10px]
             rounded-xl items-center  ' >

              <div className='flex justify-between items-center ' >
                <label htmlFor="" className=' txtclr text-nowrap ' >Choose Employee Incharge
                  <span className='text-red-600 mx-1 ' >* </span> </label>
              </div>
              <select name="incharge"
                // disabled={orderObj.incharge && orderObj.incharge.length <= 1}
                onChange={(e) => {
                  let selectedEmployees = [...orderObj.incharge]
                  let obj = { employee: e.target.value, active: false }
                  if (selectedEmployees.findIndex((obj2) => obj2.employee === e.target.value) == -1)
                    selectedEmployees.push(obj)
                  else
                    selectedEmployees = selectedEmployees.filter((obj2) => obj2.employee !== e.target.value)
                  setOrderObj((prev) => ({
                    ...prev,
                    incharge: selectedEmployees
                  }))
                }}
                className='w-full outline-none  ' id="">
                <option value=""> Select </option>
                {
                  allemp && allemp.map((obj, index) => (
                    <option value={obj._id} >{obj.emp_name} ({obj.empId}) </option>
                  ))
                }
              </select>
            </article>
          </section>
          {/* Employee phile */}
          <section className={` my-2 `} >
            <article className='flex w-full gap-2 border-slate-300 border-2 px-2 h-fit p-[10px]
             rounded-xl items-start ' >


              <article className='flex flex-wrap gap-2 ' >
                <label htmlFor="" className='flex gap-2 txtclr text-nowrap' >Incharge Employees :
                  <InfoComponent size={12} content="Green employee will be the active incharge and Red one will be the inactive incharge , 
            by clicking on the name you can transfer the active status to the other employee." />
                </label>
                {
                  orderObj && orderObj.incharge && [...orderObj.incharge].map((obj) => (
                    <div className={`  flex gap-2
                        ${obj.active ? 'bg-green-200 border-green-700 border-opacity-50 text-green-700 '
                        : ' bg-red-200 border-red-700 border-opacity-50 text-red-700 '} text-sm
                  rounded p-2 cursor-pointer border-2 `} >
                      <div
                        onClick={() => {
                          let newArray = [...orderObj.incharge].map((obj2) => {
                            if (obj2.employee == obj.employee) {
                              return {
                                employee: obj2.employee,
                                active: true
                              }
                            } else {
                              return {
                                employee: obj2.employee,
                                active: false
                              }
                            }
                          })
                          setOrderObj((prev) => ({
                            ...prev,
                            incharge: newArray
                          }))
                        }} className={` `} >

                        {allemp && allemp.find((obj2) => obj2._id == obj.employee) &&
                          (allemp.find((obj2) => obj2._id == obj.employee)).emp_name}

                      </div>
                      {[...orderObj.incharge].length > 1 && !obj.active &&
                        <button onClick={() => {
                          let filteredEmployee = [...orderObj.incharge].filter((obj2) => obj2.employee != obj.employee)
                          setOrderObj((prev) => ({
                            ...prev,
                            incharge: filteredEmployee
                          }))
                        }} className={`relative z-10 rotate-45 ${obj.active ? " border-green-700 border-opacity-50 " : " border-red-700 "} h-fit rounded-full p-[2px]
                     border-2 `} >
                          <PlusIcon size={10} />
                        </button>}
                    </div>
                  ))
                }
              </article>


            </article>

          </section>
        </section>
        <section className='col-sm-6 flex flex-col gap-2 ' >
          <InputComponent size='col-12' mandatory value={orderObj.material_no} onchange={handleChangeOrder} name="material_no"
            setobj={setOrderObj} scan label="M&M Material No :" />
          <InputComponent size='col-12' value={orderObj.quantity} type="number" onchange={handleChangeOrder} name="quantity"
            label="Quantity : " />
          <InputComponent size='col-12' value={orderObj.description} onchange={handleChangeOrder} name="description"
            label="Description :" textarea />


          {/* Close */}
          {id && <section className={` my-2 flex  gap-3 items-center col-sm-6 `} >
            <label htmlFor="" className='txtclr text-nowrap ' > Status : </label>

            <div className='flex shadow-md rounded-full p-2 px-3 items-center gap-2 ' >
              <label htmlFor="open">Open </label>
              <input type="checkbox" className='w-fit ' name='status' checked={orderObj.status == 'open'} />
            </div>
            <div className='flex shadow-md rounded-full p-2 px-3 items-center gap-2 ' >
              <label htmlFor="open">Close </label>
              <input type="checkbox" className='w-fit ' name='status' checked={orderObj.status != 'open'} />
            </div>

          </section>}

        </section>
      </main>
      <section className='flex justify-between items-center  ' >

        <h6 className='text-slate-600 text-xl ' >Stage Details </h6>

      </section>
      {
        stages && stages.map((obj, index) => (
          <main className='row bg-white rounded  container mx-auto my-3 py-2 ' >
            {console.log(obj, `stage${index + 1}`)
            }

            <h6 className='text-slate-600 text-lg ' >Stage {index + 1} </h6>
            <section className='flex flex-col col-sm-6 gap-2 ' >

              <InputComponent size='col-12' index={index} arry={stages} setobj={setStages}
                value={stages[index].confimation_no} mandatory scan label="Confirm No :"
                onchange={handleChangeStages} name="confimation_no" type="text" />
              {/* Stage name */}
              <article className='flex w-full justify-between border-slate-300 border-2 
              px-2 h-fit p-[10px] rounded-xl items-center  ' >
                <label htmlFor="" className='txtclr text-nowrap  ' >Stage Name :  </label>
                <select name="stage_name" value={stages[index].stage_name}
                  onChange={(e) => handleChangeStages(e, index)}
                  className='w-full outline-none ' id="">
                  <option value="">Select</option>
                  {
                    allStages && allStages.filter((obj2) => obj2.active).map((obj2, index) => (
                      <option value={obj2.name} >{obj2.name} </option>
                    ))
                  }
                </select>
              </article>
              {id && <InputComponent size='col-12' index={index} value={stages[index].goodQuality} label="Good Quantity :"
                onchange={handleChangeStages} name="goodQuality" type="number"
                limit={
                  (obj && obj.prev_stage && obj.prev_stage.goodQuality)
                    ? obj.prev_stage.goodQuality - (obj.badQuantity ? Number(obj.badQuantity) : 0) :

                    (stages[index].orderId && orderObj.quantity && stages[index].badQuantity) ?
                      Number(orderObj.quantity) - Number(stages[index].badQuantity) :
                      stages[index].orderId && orderObj.quantity} />}
              <section className=' my-2 ' >
                <article className='flex w-full justify-between border-slate-300 border-2 
              px-2 h-fit p-[10px] rounded-xl items-center  ' >
                  <label htmlFor="" className='txtclr text-nowrap  ' >Employee work on stage :  </label>
                  <select name="assigned_to"
                    onChange={(e) => handleChangeStages(e, index)}
                    className='w-full outline-none ' id="">
                    <option value="">Select</option>
                    {
                      allemp && allemp.map((obj2, index) => (
                        <option value={obj2._id} >{obj2.emp_name} ({obj2.empId}) </option>
                      ))
                    }
                  </select>
                </article>
              </section>
              {/* Employees */}
              <section className='my-2 flex items-center flex-wrap ' >
                <article className='flex flex-wrap w-full gap-2 border-slate-300 border-2 
              px-2 h-fit p-[10px] rounded-xl items-center  ' >
                  <label htmlFor="" className='txtclr text-nowrap ' >
                    Assigned Employees :</label>
                  {
                    obj.assigned_to && obj.assigned_to.map((obj2) => (
                      <div className='rounded-xl flex gap-3 p-2 h-fit w-fit
                     bg-slate-100 border-slate-300 border-2 ' >
                        {obj2.emp_name}
                        <button onClick={() => removeEmployeeFromStage(index, obj2._id)} className='rounded-full h-fit text-red-600 border-2
                     border-red-600 rotate-45 p-[2px]  ' >
                          <PlusIcon size={13} />  </button>
                      </div>
                    ))
                  }
                </article>
              </section>
            </section>
            <section className='col-sm-6 flex flex-col gap-2 ' >

              {id &&
                <InputComponent size="col-12" index={index} value={stages[index].badQuantity} label="Issuse products :"
                  onchange={handleChangeStages} name="badQuantity" type="number"
                  limit={
                    (obj && obj.prev_stage && obj.prev_stage.goodQuality) ?
                      obj.prev_stage.goodQuality - (obj.goodQuality ? Number(obj.goodQuality) : 0) :

                      (stages[index].orderId && orderObj.quantity && stages[index].goodQuality) ?
                        Number(orderObj.quantity) - Number(stages[index].goodQuality) :
                        stages[index].orderId && orderObj.quantity} />}
              <InputComponent size="col-12" index={index} value={stages[index].stage_description} label="Stage Description :"
                onchange={handleChangeStages} name="stage_description" textarea />
              {id && <InputComponent size="col-12" index={index} value={stages[index].coments} label="Comments on the stage :"
                onchange={handleChangeStages} name="coments" textarea />}


              {obj.materials_prepared_by &&
                <section className=' my-2 col-sm-4 col-lg-4 ' >
                  Material Prepared :

                  <button onClick={() => setShowMaterials(obj)}
                    className='bg-blue-800 text-white p-2 rounded block ' >
                    View
                  </button>
                </section>}

            </section>

            {/* Delete button */}
            <div>
              <section className='flex ms-auto w-fit gap-3 ' >

                {index == stages.length - 1 && < button onClick={() => setStages((prev) => [
                  ...prev,
                  {
                    confimation_no: '',
                    stage_name: '',
                    stage_description: '',
                    assigned_to: []
                  }])}
                  className='p-1 flex  rounded-full border-2 border-slate-500 ' >
                  <PlusIcon />
                </button>}
                {index > 0 && <button onClick={() =>
                  setStages((prev) => prev.filter((obj, index2) => index != index2))
                } className='p-1 rounded-full border-2 border-red-600 text-red-600 w-fit flex ms-auto ' >
                  <Dustbin />
                </button>}

              </section>
            </div>
          </main>
        ))
      }
      {/* Closing statement */}
      {
        orderObj.status != 'open' && orderObj._id && <>
          <main>
            <h6 className='text-slate-600 text-xl ' > Closing Details </h6>
            <section className='row bg-white rounded  container mx-auto my-3 py-2 ' >
              {console.log(orderObj, 'order')
              }
              <InputComponent label="Closed By :" value={orderObj.closed_by && orderObj.closed_by.emp_name}
                disable={orderObj.status != 'open'} />
              <InputComponent label="Closed Date :" value={orderObj.closed_date && orderObj.closed_date.slice(0, 10)}
                disable={orderObj.status != 'open'} />
              <InputComponent label="Closing Statement :" textarea value={orderObj.closing_statement}
                disable={orderObj.status != 'open'} />


            </section>
          </main>

        </>
      }
      <MaterialsPreparedModel show={showMaterial} orderObj={orderObj} getData={getParticularOrder} setshow={setShowMaterials} />
      <div className='flex mb-4 ' >

        {!id && <button onClick={() => createOrder()}
          disabled={loading}
          className='p-2 rounded ms-auto bg-blue-800 text-white w-fit ' >
          {loading ? "Loading" : " Create Order"}
        </button>}
        {id && orderObj.status == 'open' &&
          <>

            <button onClick={() => updateOrder()}
              disabled={loading == true}
              className='p-2 rounded ms-auto bg-blue-800 text-white w-fit ' >
              {loading == true ? "Loading" : " Update Order"}
            </button>
            {/* Close order */}
            <button onClick={() => setClosingModal(true)}
              disabled={loading == 'close'}
              className='p-2 rounded mx-3 bg-red-500 text-white w-fit ' >
              Close
            </button>
          </>}
        {id && orderObj.status == 'close' && emp_status == 'admin' && <button onClick={() => updateOrder('open')}
          disabled={loading == 'open'}
          className='p-2 rounded ms-auto bg-green-500 text-white w-fit ' >
          {loading == 'open' ? "Loading" : " Reopen"}
        </button>}
      </div>
      {/* Closing */}
      {closingModal && <Modal className='' centered show={closingModal}
        onHide={() => setClosingModal(false)} >
        <Modal.Header closeButton >
          Closing Stagement
        </Modal.Header>
        <Modal.Body>
          <div>
            {stages[stages.length - 1].goodQuality == 0 ||
              stages[stages.length - 1].goodQuality == undefined ? <p>
              There is no  good outputs aligned
            </p> :
              Number(orderObj.quantity) > stages[stages.length - 1].goodQuality ?
                <p> In the Last stage only {stages[stages.length - 1].goodQuality}
                  good outputs are there. </p>
                : <p> Counting in the Last stage matched </p>}

          </div>
          <InputComponent size='col-12' label="Closing statement : " value={orderObj.closing_statement} name='closing_statement'
            onchange={handleChangeOrder} textarea />
          <button onClick={() => updateOrder('close')}
            disabled={loading == 'close' || stages[stages.length - 1].goodQuality == 0 ||
              stages[stages.length - 1].goodQuality == undefined}
            className='p-2 rounded ms-auto flex bg-red-500 text-white w-fit ' >
            {loading == 'close' ? "Loading" : " Close "}
          </button>
        </Modal.Body>
      </Modal>}

    </div >
  )
}

export default OrderCreation