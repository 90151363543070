import React from 'react'

const ClosedEyeIcon = ({ size }) => {
    return (
        <svg width={size ? size : "25"} height={size ? size : "25"} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_139_1775)">
                <path d="M18.5289 18.3435C16.8195 19.6465 14.738 20.3683 12.5889 20.4035C5.58887 20.4035 1.58887 12.4035 1.58887 12.4035C2.83276 10.0854 4.558 8.06008 6.64887 6.46347M10.4889 4.64347C11.1772 4.48235 11.8819 4.40181 12.5889 4.40347C19.5889 4.40347 23.5889 12.4035 23.5889 12.4035C22.9818 13.5391 22.2579 14.6082 21.4289 15.5935M14.7089 14.5235C14.4342 14.8182 14.103 15.0546 13.735 15.2186C13.367 15.3826 12.9698 15.4707 12.567 15.4778C12.1641 15.4849 11.764 15.4108 11.3905 15.26C11.0169 15.1091 10.6776 14.8845 10.3927 14.5996C10.1078 14.3147 9.88326 13.9754 9.73238 13.6019C9.58149 13.2283 9.50739 12.8282 9.5145 12.4254C9.52161 12.0226 9.60978 11.6253 9.77374 11.2573C9.93771 10.8893 10.1741 10.5581 10.4689 10.2835" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M1.58887 1.40344L23.5889 23.4034" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_139_1775">
                    <rect width="24" height="24" fill="white" transform="translate(0.588867 0.403442)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default ClosedEyeIcon