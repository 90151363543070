import React, { useContext, useEffect } from 'react'
import { Store } from '../../Context/Storage'
import OrderTables from '../../Components/Tables/OrderTables'

const TotalOrders = () => {
    let { dasactive, setDasActive, getAllOrders, allorders } = useContext(Store)
    useEffect(() => {
        setDasActive('total')
        getAllOrders()
    }, [])
    return (
        <div className=' ' >
            <h4 className='poppins text-slate-800 text-lg' >Total Orders  </h4>

            <OrderTables data={allorders} />
        </div>
    )
}

export default TotalOrders