import React from 'react'
import DashCards from '../../Components/Home/DashCards'
import { Route, Routes } from 'react-router'
import TotalEmployee from './TotalEmployee'
import OpenOrders from '../OrderPages/OpenOrders'
import ClosedOrders from '../OrderPages/ClosedOrders'
import TotalOrders from '../OrderPages/TotalOrders'
import EmployeeDashBoard from './EmployeeDashBoard'
import OrdersResponsible from '../../Components/Employee/OrdersResponsible'
import StageHistory from './StageHistory'

const AdminDashboard = () => {
  return (
    <div>
      <DashCards />
      {/* <EmployeeCreationModal page /> */}
      <Routes>
        <Route path='/*' element={<TotalEmployee />} />
        <Route path='/openOrder' element={<OpenOrders />} />
        <Route path='/closedOrder' element={<ClosedOrders />} />
        <Route path='/totalOrder' element={<TotalOrders />} />
        <Route path='/assignedStages' element={<EmployeeDashBoard />} />
        <Route path='/inchargeOrders' element={<OrdersResponsible/>}/>
       

      </Routes>
    </div>
  )
}

export default AdminDashboard