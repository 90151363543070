import React, { useContext, useEffect, useState } from 'react'
import { Store } from '../../Context/Storage';
import { useNavigate } from 'react-router';
import SearchIcon from '../../Icon/SearchIcon';
import InfoComponent from '../InfoComponent';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ExcelDownload from '../ExcelDownload';

const OrderTables = ({ data, align, size }) => {
    console.log(data);
    let navigate = useNavigate()
    let { readableTime, getProperDate } = useContext(Store)
    let [filteredData, setFilteredData] = useState()
    let [filterObj, setFilterObj] = useState({
        from: '',
        to: ''
    })
    let handleFilterObj = (e) => {
        let { name, value } = e.target
        if (name == 'to' && filterObj.from && value < filterObj.from)
            value = filterObj.from
        if (name == 'from' && filterObj.to && value > filterObj.to)
            value = filterObj.to
        setFilterObj((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    useEffect(() => {
        if (data)
            setFilteredData(data)
    }, [data])
    let filterDataFuc = (e) => {
        let value = e.target.value.toLowerCase();
        console.log(value);

        let filterDataByDate = data.filter((obj) => {
            let fromCondition = filterObj.from ? filterObj.from <= (obj.created_date && getProperDate(obj.created_date)) : true;
            let toCondition = filterObj.to ? filterObj.to >= (obj.created_date && getProperDate(obj.created_date)) : true;
            return fromCondition && toCondition;
        });
        console.log(filterDataByDate, "date", filterObj.to);


        if (value) {
            let filteringData = filterDataByDate.filter((obj) =>
                (obj.orderId && obj.orderId.toLowerCase().indexOf(value) !== -1) ||
                (obj.material_no && obj.material_no.toLowerCase().indexOf(value) !== -1)
            );
            console.log(filteringData, 'filter');
            setFilteredData(filteringData);
        } else {
            setFilteredData(filterDataByDate);
        }
    };
    const renderTooltip = (porps) => (
        <Tooltip id="button-tooltip" className='  ' {...porps} >Search the orders by OrderID</Tooltip>
    );
    return (
        <div>
            <section className={` ${align ? align : ''} my-2 flex flex-wrap  gap-3 `} >
                <OverlayTrigger className=" "
                    placement="top" delay={{ show: 150, hide: 200 }}
                    overlay={renderTooltip}  >
                    <div className=' border-2 bg-white rounded w-fit flex gap-2 items-center px-2 relative ' >
                        <input type="text" onChange={filterDataFuc} placeholder='Search...' className='p-2 rounded outline-none ' />
                        <SearchIcon />
                        {/* <span className='absolute -right-2 -top-5 ' >

                            <InfoComponent size={12} content="Search the orders by OrderID " />
                        </span> */}
                    </div>
                </OverlayTrigger>
                <div className=' border-2 bg-white rounded w-fit flex gap-2 items-center p-2 ' >
                    From :
                    <input type="date" value={filterObj.from} name='from' onChange={handleFilterObj} className='outline-none ' />
                </div>
                <div className=' border-2 bg-white rounded w-fit flex gap-2 items-center p-2 ' >
                    To :
                    <input type="date" value={filterObj.to} name='to' onChange={handleFilterObj} className='outline-none ' />
                </div>
                <button onClick={filterDataFuc}
                    className='p-2 rounded border-2 border-green-300 w-32 bg-green-500 text-white ms-auto ' >
                    Search
                </button>


            </section>

            <main className={` table-responsive ${size ? size : "h-[60vh]"} overflow-y-scroll rounded w-full tablebg `} >
                <table className='w-full  ' >
                    <tr className='sticky top-0 bg-blue-800 text-white ' >
                        <th> SI NO </th>
                        <th> Order Id </th>
                        <th>G & G Material No </th>
                        <th> Date </th>
                        <th> Description </th>
                        <th> Material Type </th>
                        <th>Planned Quantity </th>
                        <th>Good Quantity </th>

                        <th>Closed Employee </th>

                        <th>Closed Date </th>

                        <th> Status </th>

                    </tr>
                    {
                        filteredData && [...filteredData].reverse().map((obj, index) => (
                            <tr onClick={() => navigate(`/order/${obj._id}`)}
                                className={`text-sm hover:bg-blue-50 ${index % 2 == 0 ? '' : ' rowbg '}
                                 cursor-pointer ${obj.status != 'open' && 'bg-red-50'} `} >
                                <td className='colbg ' >{index + 1} </td>
                                {console.log(obj, 'orderdetails')
                                }
                                <td>{obj.orderId} </td>
                                <td className='colbg '>{obj.material_no} </td>

                                <td className=' min-w-[70px]' > {obj.created_date && readableTime(obj.created_date)} </td>
                                <td className='max-w-[170px]  colbg text-wrap ' >{obj.description} </td>
                                <td className=' ' >{obj.material_type} </td>
                                <td className=' colbg'>{obj.quantity} </td>
                                <td>{obj.Stages && obj.Stages[obj.Stages.length - 1] &&
                                    obj.Stages[obj.Stages.length - 1].goodQuality} </td>

                                <td className=' ' >  {obj.closed_by && obj.closed_by.emp_name} </td>
                                <td className=' colbg' >{obj.closed_date && readableTime(obj.closed_date)} </td>
                                <td className=' '>{obj.status} </td>
                            </tr>
                        ))
                    }
                </table>
            </main>
            {filteredData && <div className='my-2 ' >
                <ExcelDownload data={[...filteredData].map((obj) => ({
                    "Order Id": obj.orderId,
                    "Created Date ": obj.created_date && readableTime(obj.created_date),
                    "Material Prepared": obj.material_type,
                    "Incharges": obj.incharge && obj.incharge.map((obj2) => obj2.employee.emp_name).join(' , '),
                    "Stage Cnfirmation No": obj.Stages && obj.Stages.map((obj2) => obj2.confimation_no).join(' , '),
                    "Status": obj.status,
                    "Closed Date ": obj.closed_date && readableTime(obj.closed_date),
                    "Closed Employee": obj.closed_by && obj.closed_by.emp_name,
                    "Closing StateMents": obj.closing_statement,
                    "Planned quantity": obj.quantity,
                    "Achieved quantity": obj.Stages && obj.Stages[obj.Stages.length - 1] && obj.Stages[obj.Stages.length - 1].goodQuality

                }))} name="OrderReport" />
            </div>}
        </div>
    )
}

export default OrderTables