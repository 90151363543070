import React, { useContext, useEffect } from 'react'
import { Store } from '../../Context/Storage'
import { useNavigate } from 'react-router'

const TopNav = () => {
    let navigate = useNavigate()
    let { getUser, user } = useContext(Store)
    useEffect(() => {
        getUser()
    }, [])
    return (
        <div className='flex poppins items-center py-2 justify-between ' >
            <section  >
                <h5 className='text-lg text-slate-600 ' > Hi {user && user.emp_name} </h5>
                <h5 className='text-2xl fw-semibold text-slate-800 ' >Welcome to Geba </h5>
            </section>
            <section className='flex gap-2 items-center ' >
                <img onClick={() => navigate(`/employee/${sessionStorage.getItem('gebba_user')}`)}
                    src={user && user.profile_pic ? user.profile_pic : require('../../Assests/bgimage.webp')} alt="Profile image"
                    className='w-12 h-12 rounded-full cursor-pointer object-cover border-2  ' />
            </section>
        </div>
    )
}

export default TopNav