import React from 'react'

const JournalIcon = ({ size }) => {
    return (
        <svg width={size ? size : "16"} height={size ? size : "16"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_86_1891)">
                <path d="M5.636 18.3641C7.10845 19.8365 9.04594 20.7528 11.1183 20.9568C13.1906 21.1608 15.2696 20.6399 17.0009 19.4828C18.7322 18.3258 20.0087 16.6041 20.6129 14.6113C21.217 12.6185 21.1115 10.4779 20.3142 8.5542C19.5169 6.63051 18.0772 5.04282 16.2404 4.0617C14.4037 3.08057 12.2835 2.76673 10.2413 3.17364C8.19909 3.58056 6.36116 4.68305 5.04073 6.29325C3.72031 7.90344 2.99909 9.92169 3 12.0041V14.0001"
                 stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M1 12L3 14L5 12M11 8V13H16" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_86_1891">
                    <rect width="24" height="24" fill="currentColor" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default JournalIcon