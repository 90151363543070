import { Html5QrcodeScanner } from 'html5-qrcode';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import ScanIcon from '../Icon/ScanIcon';

const ScannerButton = ({ setobj, name, index, arry }) => {
    let [show, setshow] = useState(false)
    let scanner;
    let scanValue = () => {
        scanner = new Html5QrcodeScanner(
            "scannreader", {
            fps: 20, qrbox: {
                width: 250,
                height: 100
            }
        },
        );
        function onScanSuccess(result) {
            console.log(result);

            setTimeout(() => {
                if (arry) {
                    let updatedValue = [...arry]
                    updatedValue[index] = {
                        ...updatedValue[index],
                        [name]: result
                    }
                    setobj(updatedValue)
                }
                else
                    setobj((prev) => ({
                        ...prev,
                        [name]: result
                    }))
                setshow(false)
                scanner.clear()
            }, 3000);

        }
        function onScanFailure(error) {
            console.log(error);
            // scanner.clear()
        }
        scanner.render(onScanSuccess, onScanFailure);

    }
    useEffect(() => {
        if (show) {
            scanValue()
        }
    }, [show])
    return (
        <>
            {/* <div id='scannreader'
                className=' w-[300px] mx-auto '  >
            </div> */}
            <button onClick={() => {
                setshow(true);
            }} className='p-[10px] text-sm bg-green-50 border-opacity-40 border-green-700 text-green-700 
             border-2 rounded-xl flex items-center gap-2 h-fit text-nowrap' >
                <ScanIcon/>
                <span className='d-none d-lg-block ' >Scan now </span>
            </button>
            <Modal show={show} centered onHide={() => {
                setshow(false);
                if (scanner)
                    scanner.clear()
            }} >
                <Modal.Body>

                    <div id='scannreader'
                        className=' w-[300px] mx-auto '  >
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default ScannerButton