import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { port } from '../App'
import InputComponent from '../Components/InputComponent'
import { toast } from 'react-toastify'
import LeftArrow from '../Icon/LeftArrow'

const MachinaryPage = () => {
    let { id } = useParams()
    let navigate = useNavigate()
    let [loading, setloading] = useState(false)
    let [machinery, setMachinery] = useState({
        machineryName: '',
        description: '',
        machineryId: '',
        activeStatus: true
    })
    let handleChange = (e) => {
        let { name, value } = e.target
        setMachinery((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    let saveMachinery = async () => {
        if (machinery.machineryId != '' && machinery.machineryName != '') {
            setloading('save')
            await axios.post(`${port}/api/machinery`, machinery).then((response) => {
                console.log(response.data, "machine");
                setloading(false)
                toast.success('Machine added successfully')
                setMachinery({
                    machineryName: '',
                    description: '',
                    machineryId: '',
                    activeStatus: true
                })

            }).catch((error) => {
                console.log(error);
                setloading(false)
            })
        }
        else {
            toast.warning('Enter all the required fields.')
        }
    }
    let updateMachinery = async () => {
        if (machinery.machineryId != '' && machinery.machineryName != '') {
            setloading('update')
            machinery.id = id
            await axios.put(`${port}/api/machinery`, machinery).then((response) => {
                console.log(response.data, "machine");
                setloading(false)
                toast.success('Machine Updated successfully')
                setMachinery(response.data)
            }).catch((error) => {
                console.log(error);
                setloading(false)
            })
        }
        else {
            toast.warning('Enter all the required fields.')
        }
    }
    let getParticularData = async () => {
        await axios.get(`${port}/api/machinery/${id}`).then((response) => {
            setMachinery(response.data)
            console.log(response.data, "getMachine");
        }).catch((error) => {
            console.log(error);
        })
    }
    useEffect(() => {
        if (id)
            getParticularData()
    }, [id])
    return (
        <div className='container' >
            <button onClick={() => navigate('/machines')} className='p-2 rounded bg-slate-700 text-white ' >
                <LeftArrow />
            </button>
            <h5 className='text-center text-xl fw-semibold ' >
                {id ? "Update" : "Create"} a Machinery Details
            </h5>
            {/* Data adding */}
            <section className='row my-3 bg-white rounded p-2 ' >
                <InputComponent label="Machine Id" mandatory onchange={handleChange} value={machinery.machineryId}
                    name="machineryId" scan setobj={setMachinery} />
                <InputComponent label='Machine Name' mandatory onchange={handleChange} value={machinery.machineryName}
                    name="machineryName" type="text" />
                <InputComponent label="Description" onchange={handleChange} value={machinery.description}
                    name="description" textarea />
                {/* Change */}
                <div>
                    <label htmlFor="">Status </label>
                    <div className='flex items-center gap-2 my-2 ' >
                        <label htmlFor="active">Active </label>
                        <input onClick={() => setMachinery((prev) => ({ ...prev, activeStatus: true }))} type="radio" id='active' checked={machinery.activeStatus} name='activeStatus' />
                    </div>
                    <div className='flex items-center gap-2 my-2 ' >
                        <label htmlFor="inactive">InActive </label>
                        <input onClick={() => setMachinery((prev) => ({ ...prev, activeStatus: false }))} type="radio" id='inactive' checked={!machinery.activeStatus} name='activeStatus' />
                    </div>
                </div>
            </section>
            {!id && <button disabled={loading == 'save'} onClick={saveMachinery}
                className='bg-green-400 text-whtie
             p-2 rounded text-white ms-auto flex ' >
                {loading == 'save' ? 'Loading...' : "Save"}
            </button>}
            {id && <button disabled={loading == 'update'} onClick={updateMachinery}
                className='bg-blue-500 text-whtie
             p-2 rounded text-white ms-auto flex ' >
                {loading == 'update' ? 'Loading...' : "Update"}
            </button>}
        </div>
    )
}

export default MachinaryPage