import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Store } from '../Context/Storage';
import MaterialModel from './MaterialModel';
import axios from 'axios';
import { port } from '../App';

const MaterialsPreparedModel = ({ show, orderObj, getData, setshow }) => {
    console.log(show, orderObj, "materials");
    let [materialShow, setMaterialShow] = useState(false)
    let [activeIncharge, setActiveIncharge] = useState()
    let [data, setData] = useState()
    let { readableTime } = useContext(Store)
    useEffect(() => {
        if (show) {
            setData(show)
        }
    }, [show])
    let getParticularStage = () => {
        axios.get(`${port}/api/stages/${show._id}`).then((response) => {
            console.log(response.data, "stage");
            setData(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }
    useEffect(() => {
        if (orderObj)
            setActiveIncharge(orderObj.incharge &&
                orderObj.incharge.find((obj) => obj.active) &&
                orderObj.incharge.find((obj) => obj.active).employee)
    }, [orderObj._id])
    return (
        <div>
            {show && <Modal className=' ' centered show={show}
                onHide={() => setshow(false)} >
                <Modal.Header className=' ' closeButton >
                    Materials scanned at {show && show.stage_name}
                </Modal.Header>
                <Modal.Body>
                    <button onClick={() => { setMaterialShow(true) }} className='ms-auto flex my-3 bg-blue-600 px-2  rounded text-sm p-1 text-white ' >
                        Scan Material
                    </button>
                    <MaterialModel id={show._id} activeIncharge={activeIncharge} show={materialShow}
                        getData={getParticularStage} setshow={setMaterialShow} />
                    <div className='table-responsive tablebg rounded-xl max-h-[60vh] overflow-y-scroll ' >
                        <table className='w-full ' >
                            <tr className='sticky top-0 bg-white ' >
                                <th>Machine </th>

                                <th>Material </th>
                                <th>Part Number </th>
                                <th>Employee </th>
                                <th>Incharge </th>
                                <th>Scanned Time </th>
                            </tr>
                            {
                                data && data.materials_prepared_by && data.materials_prepared_by.length > 0 ?
                                    data.materials_prepared_by.map((obj) => (
                                        <tr>
                                            <td>{obj.machinery} </td>

                                            <td>{obj.material} </td>
                                            <td>{obj.part_number} </td>
                                            <td>{obj.employee && obj.employee.emp_name} </td>
                                            <td>{obj.incharge && obj.incharge.emp_name} </td>
                                            <td>{obj.scannedTime && readableTime(obj.scannedTime)} </td>
                                        </tr>
                                    )) :
                                    <tr className='' >
                                        <td colSpan={5} className=' text-center my-3' >
                                            No Materials added by employees
                                        </td>
                                    </tr>
                            }
                        </table>

                    </div>
                </Modal.Body>
            </Modal>}
        </div>
    )
}

export default MaterialsPreparedModel