import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { port } from '../App'

const ForgotPassword = () => {
    let navigate = useNavigate()
    let [errorMsg, setErrorMsg] = useState()
    let [recievedOtp, setReceivedOtp] = useState()
    let [sendotp, setsendingOtp] = useState()
    let [password, setPassword] = useState()
    let [loading, setloading] = useState(false)
    let [email, setemail] = useState()
    let getOtp = (e) => {
        e.preventDefault();
        if (!email)
            return toast.warning('Fill the field')
        setloading(true)
        axios.get(`${port}/api/otp/${email}`).then((response) => {
            console.log(response.data);
            setReceivedOtp({
                otp: response.data.otp,
                id: response.data.id

            })
            setloading(false)
            toast.success('Otp has been sended to the registered mail.')
        }).catch((error) => {
            console.log(error);
            setloading(false)

            toast.error(error.response.data)
        })
    }
    let ChangePassword = () => {
        setloading(true)
        setErrorMsg('')
        if (Number(sendotp) == Number(recievedOtp.otp))
            axios.put(`${port}/api/user`, {
                id: recievedOtp.id,
                password: password
            }).then((response) => {
                console.log(response.data);
                toast.success('Password has been saved successfully')
                navigate('/login')
                setloading(false)

            }).catch((error) => {
                console.log(error);
                toast.error(error.response.data)
                setloading(false)

            })
        else {
            toast.warning("Enter the Correct OTP")
            setloading(false)
        }
    }
    return (
        <div className='singinbg' >
                <img src={require('../Assests/GebaLogo.webp')} alt="Logo" className='absolute  top-10 left-10 ' />

            <main className='min-h-[100vh] flex ' >
                <section className='m-auto bg-white col-sm-8 col-lg-4 p-4 
                px-5 rounded-xl border-2  ' >

                    <form action="" className='text-start ' >
                        <h4 className='text-center text-3xl my-3' > Forgot Password </h4>
                        <div className='text-start my-3 ' >
                            <p className='mb-1 fw-semibold ' >  Email or Employee Id or Phone </p>
                            <input type="text" value={email} name='email' onChange={(e) => setemail(e.target.value)}
                                className='mt-3 w-full p-3 rounded-lg outline-none border-2  ' />
                        </div>

                        {recievedOtp &&
                            <section>
                                <div className='text-start my-3 ' >
                                    <p className='mb-1 fw-semibold ' > Enter the OTP </p>
                                    <input type="text" value={sendotp}
                                        onChange={(e) => setsendingOtp(e.target.value)}
                                        className='mt-3 w-full p-3 rounded-lg outline-none border-2  ' />
                                </div>
                                <div className='text-start my-3 ' >
                                    <p className='mb-1 fw-semibold ' > Enter New Password </p>
                                    <input type="text" value={password} name='email'
                                        onChange={(e) => setPassword(e.target.value)}
                                        className='mt-3 w-full p-3 rounded-lg outline-none border-2  ' />
                                </div>
                            </section>
                        }



                        <p className='h-[15px] text-red-600 text-center ' >{errorMsg} </p>
                        {!recievedOtp && <button disabled={loading} onClick={getOtp} type='button'
                            className='w-full rounded-full text-white btngrd2 p-3' >
                            {loading ? "Loading..." : "Get Otp"}
                        </button>}
                        {recievedOtp && <button disabled={loading} onClick={ChangePassword} type='button'
                            className='w-full rounded-full text-white btngrd2 p-3' >
                            {loading ? "Loading..." : "Change Password"}
                        </button>}
                    </form>
                    <article className='flex justify-between my-3 ' >
                        {/* <button onClick={() => navigate('/forgotPassword')} className='my-3 text-center ' >
                            Forgot Password ?
                        </button> */}
                        <button onClick={() => navigate('/login')} className='ms-auto ' >
                            Sign In
                        </button>
                    </article>
                </section>
            </main>
        </div>
    )
}

export default ForgotPassword