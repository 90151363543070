import React from 'react'

const EyeIcon = ({ size }) => {
    return (
        <svg width={size ? size : "25"} height={size ? size : "25"} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_139_1778)">
                <path d="M1.18359 12.7694C1.18359 12.7694 5.18359 4.76941 12.1836 4.76941C19.1836 4.76941 23.1836 12.7694 23.1836 12.7694C23.1836 12.7694 19.1836 20.7694 12.1836 20.7694C5.18359 20.7694 1.18359 12.7694 1.18359 12.7694Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12.1836 15.7694C13.8404 15.7694 15.1836 14.4263 15.1836 12.7694C15.1836 11.1126 13.8404 9.76941 12.1836 9.76941C10.5267 9.76941 9.18359 11.1126 9.18359 12.7694C9.18359 14.4263 10.5267 15.7694 12.1836 15.7694Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_139_1778">
                    <rect width="24" height="24" fill="white" transform="translate(0.183594 0.769409)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default EyeIcon