import React, { useContext, useEffect } from 'react'
import EmployeeCreationModal from '../Components/Home/EmployeeCreationModal'
import { Store } from '../Context/Storage'
import DashCards from '../Components/Home/DashCards'
import TopNav from '../Components/Home/TopNav'
import { Route, Routes } from 'react-router'
import TotalEmployee from './Employee/TotalEmployee'
import OpenOrders from './OrderPages/OpenOrders'
import ClosedOrders from './OrderPages/ClosedOrders'
import TotalOrders from './OrderPages/TotalOrders'
import EmployeeDashBoard from './Employee/EmployeeDashBoard'
import AdminDashboard from './Employee/AdminDashboard'
import { Spinner } from 'react-bootstrap'

const DashBoardContent = () => {
    let { setactive, getUser, user, } = useContext(Store)
    useEffect(() => {
        setactive('dash')
        if (!user)
            getUser()
    }, [])
    return (
        <div>
            {user ?
                <AdminDashboard /> :
                <div className='h-[60vh] flex ' >
                    <Spinner className='m-auto ' />

                </div>}


        </div>
    )
}

export default DashBoardContent