import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { port } from '../../App'
import { Store } from '../../Context/Storage'
import StageNameModel from './StageNameModel'

const StageNameListing = () => {
    let { allStages, setAllStages, getAllStageNames } = useContext(Store)
    let [showModal, setShowModal] = useState(false)
    let [selectedId, setSelectedId] = useState()
    useEffect(() => {
        getAllStageNames()
    }, [])
    return (
        <div>
            <section className='flex items-center justify-between gap-3 flex-wrap  ' >
                <h5 className='poppins ' > All Stages </h5>
                <button onClick={() => setShowModal(true)} className='bg-blue-700 text-white p-2 text-sm rounded ' >
                    Add Stages
                </button>
            </section>
            {/* Table */}
            <main className='tablebg max-h-[60vh] overflow-y-scroll table-responsive my-3 rounded ' >
                <table className='w-full ' >
                    <tr className=' sticky top-0 bg-blue-800 text-white ' >
                        <th>SI No</th>
                        <th> Stage Name </th>
                        <th> Status  </th>
                    </tr>
                    {
                        allStages && allStages.map((obj, index) => (
                            <tr onClick={()=>{
                                setShowModal(true) 
                                setSelectedId(obj)
                            }} className={` hover:bg-blue-50 cursor-pointer ${index % 2 == 0 ? '' : ' rowbg '}  `} >
                                <td className='colbg w-[4rem] ' >{index + 1} </td>
                                <td> {obj.name} </td>
                                <td className='colbg ' > {obj.active ? 'Active' : 'Inactive'} </td>
                            </tr>
                        ))
                    }
                </table>
            </main>
            <StageNameModel show={showModal} id={selectedId} setid={setSelectedId} setshow={setShowModal} />
        </div>
    )
}

export default StageNameListing