import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import InputComponent from './InputComponent'
import axios from 'axios'
import { port } from '../App'
import { toast } from 'react-toastify'
import { Store } from '../Context/Storage'

const MaterialModel = ({ show, setshow, getData, id, 
    activeIncharge, getParticularStage, onchange }) => {
    let { allMachine } = useContext(Store)
    let [obj, setObj] = useState({
        employee: sessionStorage.getItem('gebba_user'),
        material: '',
        machinery: '',
        part_number: '',
        incharge: activeIncharge
    })
    let [loading, setloading] = useState(false)
    let handleObj = (e) => {
        let { name, value } = e.target
        setObj((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    let submitMaterial = () => {
        console.log(obj);
        // return
        setloading(true)
        axios.put(`${port}/api/material/${id}`, obj).then((response) => {
            console.log(response.data);
            toast.success('Added')
            setshow(false)
            if (getData){
                getData()
            }
            if (getParticularStage)
                getParticularStage()
            setObj({
                employee: sessionStorage.getItem('gebba_user'),
                material: '',
                machinery: '',
                incharge: activeIncharge
            })
            setloading(false)
        }).catch((error) => {
            console.log(error);
            setloading(false)
        })
    }
    useEffect(() => {
        setObj((prev) => ({
            ...prev,
            incharge: activeIncharge
        }))
    }, [activeIncharge])
    console.log(activeIncharge, 'activeincharge');

    return (
        <div>
            <Modal className='' centered show={show} onHide={() => setshow(false)} >
                <Modal.Header closeButton >
                    Material Scanning Form
                </Modal.Header>
                <Modal.Body>
                    <div className='w-full ' >
                        <div className=' ' >
                            <label htmlFor="" className='px-1 ' > Machine </label>
                            <select name="machinery"
                                className='block w-full p-2 rounded outline-none border-2 my-1 '
                                onChange={handleObj}
                                value={obj.machinery} id="">
                                <option value="">Select</option>
                                {
                                    allMachine && allMachine.filter((obj) => obj.activeStatus).map((obj) => (
                                        <option value={`${obj.machineryName}${obj.machineryId ? `(${obj.machineryId})` : ''} `}>{obj.machineryName} </option>
                                    ))
                                }
                            </select>

                        </div>

                        <InputComponent size="col-12" value={obj.material}
                            setobj={setObj} name="material" onchange={handleObj}
                            label="GRN No :" scan />
                        <InputComponent size="col-12" value={obj.part_number}
                            setobj={setObj} name="part_number" onchange={handleObj}
                            label="Part No :" scan />
                    </div>
                    <button disabled={loading} onClick={() => submitMaterial()} className='p-2 bg-blue-600 text-white ms-auto flex rounded  ' >
                        {loading ? "Loading.." : "Submit"}
                    </button>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default MaterialModel