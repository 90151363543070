import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './Pages/Login';
import DashBoard from './Pages/DashBoard';
import Protect from './Pages/Protect';
import SignUp from './Pages/SignUp';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ForgotPassword from './Pages/ForgotPassword';
import './Css/home.css'





// export let port = 'http://localhost:9110'
// export let port = 'https://0mbjdcn5-9000.inc1.devtunnels.ms'
export let port = 'https://backend.gebacables.in/'

function App() {
  return (
    <div className="bg-sky-50 poppins">
      <BrowserRouter>
        <ToastContainer position='top-center' autoClose={3000} />
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/forgotPassword' element={<ForgotPassword />} />
          <Route path='/*' element={<Protect Child={DashBoard} />} />
          <Route path='/signup' element={<SignUp />} />
        </Routes>

      </BrowserRouter>

    </div>
  );
}

export default App;
