import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { port } from '../../App'
import { toast } from 'react-toastify'
import InputComponent from '../../Components/InputComponent'
import Storage, { Store } from '../../Context/Storage'

const StageNameModel = ({ show, setshow, id, setid }) => {
    let { getAllStageNames } = useContext(Store)
    let [formObj, setFormObj] = useState({
        name: '',
        active: ''
    })
    let [loading, setloading] = useState(false)
    let handleFormObj = (e) => {
        let { name, value } = e.target
        setFormObj((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    let reset = () => {
        setshow(false)
        setloading(false)
        setFormObj({
            name: '',
            active: ''
        })
        setid(false)
    }
    let postData = () => {
        setloading('save')
        axios.post(`${port}/api/stageName`, formObj).then((response) => {
            console.log(response.data);
            toast.success('Stage Name have been added')
            getAllStageNames()
            reset()
        }).catch((error) => {
            console.log(error);
            setloading(false)
            toast.error('Error occured')
        })
    }
    let updateData = () => {
        setloading('update')
        axios.put(`${port}/api/stageName/${id._id}`, formObj).then((response) => {
            console.log(response.data);
            toast.success('Stage Name have been Updated')
            getAllStageNames()
            reset()
        }).catch((error) => {
            console.log(error);
            setloading(false)
            toast.error('Error occured')
        })
    }
    let deleteData = () => {
        setloading('delete')
        axios.delete(`${port}/api/stageName/${id._id}`).then((response) => {
            console.log(response.data);
            toast.success('Deleted Successfully')
            getAllStageNames()
            reset()
            setloading(false)
        }).catch((error) => {
            console.log(error);
            setloading(false)
            toast.error('Error occured')
        })
    }
    useEffect(() => {
        if (id)
            setFormObj(id)
    }, [id])
    return (
        <div>
            <Modal className=' ' centered show={show}
                onHide={reset} >
                <Modal.Header closeButton >
                    Stage Details
                </Modal.Header>
                <Modal.Body>
                    <InputComponent label='Stage Name : ' size='col-12' value={formObj.name} onchange={handleFormObj}
                        name='name' type='text' />
                    <div className=' flex w-full justify-between border-slate-300 border-2 px-2 h-fit p-[10px]
             rounded-xl items-start'  >
                        <label htmlFor="" className='txtclr text-nowrap ' > Active : </label>
                        <select name="active" className='w-full outline-none ' value={formObj.active}
                            onChange={handleFormObj} id="">
                            <option value=""> Select</option>
                            <option value={true}>Active</option>
                            <option value={false}>Inactive</option>
                        </select>
                    </div>
                    <section className='flex items-center ' >

                        {!id && <button onClick={postData} className={`mt-3 text-white text-sm flex ms-auto p-2 rounded bg-green-500  `} disabled={loading == 'save'} >
                            {loading == 'save' ? 'Loading' : "Save"}
                        </button>}
                        {id && <button onClick={updateData} className={`mt-3 text-white text-sm flex p-2 rounded bg-blue-500  `}
                            disabled={loading == 'update'} >
                            {loading == 'update' ? 'Loading' : "Update"}
                        </button>}
                        {id && <button onClick={deleteData} className={`mt-3 text-white text-sm flex ms-auto p-2 rounded bg-red-500  `}
                            disabled={loading == 'delete'} >
                            {loading == 'delete' ? 'Loading' : "Delete"}
                        </button>}
                    </section>

                </Modal.Body>
            </Modal>

        </div>
    )
}

export default StageNameModel