import React, { useState } from 'react'
import NavbarComponent from '../Components/NavbarComponent'
import EmployeeCreationModal from '../Components/Home/EmployeeCreationModal'
import SideBar from '../Components/SideBar'
import { Route, Routes } from 'react-router'
import DashBoardContent from './DashBoardContent'
import { Offcanvas } from 'react-bootstrap'
import BurgerIcon from '../Icon/BurgerIcon'
import OrderCreation from './OrderPages/OrderCreation'
import TopNav from '../Components/Home/TopNav'
import StagePage from './Employee/StagePage'
import ParticularEmployee from './Employee/ParticularEmployee'
import ParticularOrder from './OrderPages/ParticularOrder'
import EmployeeProtect from '../Components/AuthPage/EmployeeProtect'
import StageHistory from './Employee/StageHistory'
import MachineryAllPage from './MachineryAllPage'
import MachinaryPage from './MachinaryPage'
import StageNameListing from './StageName/StageNameListing'

const DashBoard = () => {
    let [show, setshow] = useState(false)
    return (
        <div className=' ' >
            <section className='d-lg-none bg-white shadow-sm z-20 sticky top-0 flex justify-between py-4 px-4 '>
                <img src={require('../Assests/GebaLogo.webp')} alt="Gebalogo" className=' ' />
                <button onClick={() => setshow(!show)} className=' w-10 h-10 flex items-center 
                justify-center rounded-xl border-2 ' >
                    <BurgerIcon />
                </button>
            </section>

            <main className='flex ' >

                <div className='d-lg-none ' >
                    <Offcanvas show={show} onHide={() => setshow(false)}>
                        <Offcanvas.Body className='p-0 ' >
                            <SideBar setshow={setshow} />
                        </Offcanvas.Body>
                    </Offcanvas>
                </div>

                <div className=' d-none d-lg-block  sticky h-[100vh] top-0 w-[270px] ' >
                    <SideBar />
                </div>
                <section className='flex-1 bgSide container-fluid mx-auto ' >
                    <TopNav />
                    <Routes>
                        <Route path='/*' element={<DashBoardContent />} />
                        <Route path='/orderCreation' element={<EmployeeProtect Child={OrderCreation} emp="employee" />} />
                        <Route path='/stage/:id' element={<StagePage />} />
                        <Route path='/employee/:id' element={<ParticularEmployee />} />
                        <Route path='/order/:id' element={<OrderCreation />} />
                        <Route path='/stageHistory' element={<StageHistory />} />
                        <Route path='/machines' element={<MachineryAllPage />} />
                        <Route path='/machine/:id?' element={<MachinaryPage />} />
                        <Route path='/stageName' element={<StageNameListing/>}/>



                    </Routes>
                </section>
            </main>

        </div>
    )
}

export default DashBoard