import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Store } from '../../Context/Storage'

const MachineTable = ({ data }) => {
    let [filteredData, setFilteredData] = useState()
    let { readableTime } = useContext(Store)
    let navigate = useNavigate()
    useEffect(() => {
        setFilteredData(data)
    }, [data])
    return (
        <div>
            <main className={` table-responsive h-[60vh] overflow-y-scroll rounded w-full tablebg `} >
                <table className='w-full  '>
                    <tr className='sticky top-0 bg-blue-800 text-white ' >
                        <th> SI NO </th>
                        <th> Machine Id </th>
                        <th>Machine Name </th>
                        <th> Description </th>
                        <th> Status </th>

                    </tr>
                    {
                        filteredData && [...filteredData].reverse().map((obj, index) => (
                            <tr onClick={() => navigate(`/machine/${obj._id}`)}
                                className={`text-sm hover:bg-blue-50 ${index % 2 == 0 ? '' : ' rowbg '} cursor-pointer ${!obj.activeStatus && 'bg-red-50'} `} >
                                <td className='colbg ' >{index + 1} </td>
                                <td>{obj.machineryId} </td>
                                <td className='colbg ' >{obj.machineryName} </td>
                                <td className='max-w-[170px] text-wrap ' >{obj.description} </td>
                                <td className='colbg ' >{obj.activeStatus ? 'Active' : 'Inactive'} </td>
                            </tr>
                        ))
                    }

                </table>
            </main>
        </div>
    )
}

export default MachineTable