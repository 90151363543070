import React, { useContext, useEffect } from 'react'
import { Store } from '../Context/Storage'
import MachineTable from '../Components/Tables/MachineTable'
import { useNavigate } from 'react-router'
import { Spinner } from 'react-bootstrap'

const MachineryAllPage = () => {
    let { getAllMachine, allMachine, setactive } = useContext(Store)
    let navigate = useNavigate()
    useEffect(() => {
        setactive('machine')
        if (allMachine)
            getAllMachine()
    }, [])
    return (
        <div>
            <section className='flex flex-wrap justify-between items-center  ' >
                <h5 className='my-3 ' >All Machines </h5>
                <button onClick={() => navigate('/machine')} className='p-2 rounded bg-blue-600 text-white text-sm ' >
                    Create Machine
                </button>

            </section>
            {allMachine == undefined ?
                <div className='h-[70vh] flex ' >
                    <Spinner className='m-auto ' />
                </div> : < MachineTable data={allMachine} />}
        </div>
    )
}

export default MachineryAllPage