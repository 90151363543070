import React, { useContext, useEffect, useState } from 'react'
import { Store } from '../../Context/Storage'
import { useNavigate } from 'react-router'
import axios from 'axios'
import { port } from '../../App'

const DashCards = () => {
    let { dasactive, setDasActive, getAllEmp, allemp, getAllOrders,
        allorders, user, getUser } = useContext(Store)
    let [inchargeCount, setInchargeCount] = useState()
    let [stagesCounts, setStageCounts] = useState()
    let navigate = useNavigate()
    useEffect(() => {
        getAllEmp()
        getAllOrders()
        getInchargeOrders()
        getResponsibleStage()
        if (!user)
            getUser()
    }, [])
    let getInchargeOrders = () => {
        console.log(`${port}/api/inchargeOrders/${sessionStorage.getItem('gebba_user')}`);
        axios.get(`${port}/api/inchargeOrders/${sessionStorage.getItem('gebba_user')}`).then((response) => {
            setInchargeCount(response.data.length)
            console.log(response.data,'incharge');
            
        }).catch((error) => {
            console.log(error);
        })
    }
    let getResponsibleStage = () => {
        axios.get(`${port}/api/stage?empId=${sessionStorage.getItem('gebba_user')}&status=open`).then((response) => {
            setStageCounts(response.data.length);
            console.log(response.data, "allstage");
        }).catch((error) => {
            console.log(error);
        })
    }
    return (
        <div className='poppins mb-3 ' >

            <main className='row container justify-between mx-auto px-0 ' >
                {/* Card 1 */}
                {user && user.emp_type == 'admin' && <article className='col-6 col-lg-3 ' >

                    <section onClick={() => navigate('/')} className={`justify-between items-center my-2 flex p-3 rounded-2xl ${dasactive == 'emp' ? "btngrd text-slate-50  " : "bg-white"} shadow-sm cursor-pointer duration-500 `} >

                        <div>
                            <p className={` mb-1 fw-semibold ${dasactive == 'emp' ? "text-slate-300 " : " text-slate-500"} `}>Total Employee </p>
                            <p className='mb-1 fw-semibold ' >{allemp && allemp.length} </p>
                        </div>
                        <img src={require('../../Assests/profile.webp')} className='w-12 h-12 ' alt="Profile" />
                    </section>
                </article>}
                {/* Card 2 */}
                {user && user.emp_type == 'admin' &&
                    <article className='col-6 col-lg-3 ' >
                        <section onClick={() => navigate('/openOrder')} className={`justify-between items-center my-2 flex p-3 rounded-2xl ${dasactive == 'open' ? "btngrd text-slate-50  " : "bg-white"} shadow-sm cursor-pointer duration-500 `} >

                            <div>
                                <p className={` mb-1 fw-semibold ${dasactive == 'open' ? "text-slate-300 " : " text-slate-500"} `}>Open Orders </p>
                                <p className='mb-1 fw-semibold ' >{allorders && allorders.filter((obj) => obj.status == 'open').length} </p>
                            </div>
                            <img src={require('../../Assests/open.webp')} className='w-12 h-12 ' alt="Open" />

                        </section>
                    </article>}
                {/* Card 3 */}
                {user && user.emp_type == 'admin' &&
                    <article className='col-6 col-lg-3 ' >

                        <section onClick={() => navigate('/closedOrder')}
                            className={`justify-between items-center my-2 flex p-3 rounded-2xl ${dasactive == 'close' ? "btngrd text-slate-50  " : "bg-white"} shadow-sm cursor-pointer duration-500 `} >

                            <div>
                                <p className={` mb-1 fw-semibold ${dasactive == 'close' ? "text-slate-300 " : " text-slate-500"} `}>Closed Orders </p>
                                <p className='mb-1 fw-semibold ' > {allorders && allorders.filter((obj) => obj.status == 'close').length} </p>
                            </div>
                            <img src={require('../../Assests/close.webp')} className='w-12 h-12 ' alt="Close" />

                        </section>
                    </article>}
                {/* Card 4 */}
                {user && user.emp_type == 'admin' &&
                    <article className='col-6 col-lg-3 ' >

                        <section onClick={() => navigate('/totalOrder')} className={`justify-between items-center my-2 flex p-3 rounded-2xl ${dasactive == 'total' ? "btngrd text-slate-50  " : "bg-white"} shadow-sm cursor-pointer duration-500 `} >

                            <div>
                                <p className={` mb-1 fw-semibold ${dasactive == 'total' ? "text-slate-300 " : " text-slate-500"} `}>Total Orders </p>
                                <p className='mb-1 fw-semibold ' > {allorders && allorders.length} </p>
                            </div>
                            <img src={require('../../Assests/all.webp')} className='w-12 h-12 ' alt="all" />

                        </section>
                    </article>}
                {/* Card 5 */}
                {user &&
                    <article className='col-6 col-lg-3 ' >

                        <section onClick={() => navigate('/assignedStages')} className={`justify-between items-center my-2 flex p-3 rounded-2xl ${dasactive == 'assign' ? "btngrd text-slate-50  " : "bg-white"} shadow-sm cursor-pointer duration-500 `} >

                            <div>
                                <p className={` mb-1 fw-semibold ${dasactive == 'assign' ? "text-slate-300 " : " text-slate-500"} `}>Assigned Stages </p>
                                <p className='mb-1 fw-semibold ' > {stagesCounts}  </p>
                            </div>
                            <img src={require('../../Assests/open.webp')} className='w-12 h-12 ' alt="all" />

                        </section>
                    </article>
                }
                {user && user.emp_type != 'admin' &&
                    <article className='col-6 col-lg-3 ' >

                        <section onClick={() => navigate('/inchargeOrders')} className={`justify-between items-center my-2 flex p-3 rounded-2xl 
                            ${dasactive == 'incharge' ? "btngrd text-slate-50  " : "bg-white"} shadow-sm cursor-pointer duration-500 `} >

                            <div>
                                <p className={` mb-1 fw-semibold ${dasactive == 'incharge' ? "text-slate-300 " : " text-slate-500"} `}>Incharge Orders </p>
                                <p className='mb-1 fw-semibold ' > {inchargeCount} </p>
                            </div>
                            <img src={require('../../Assests/all.webp')} className='w-12 h-12 ' alt="all" />

                        </section>
                    </article>
                }

            </main>



        </div>
    )
}

export default DashCards