import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { port } from '../../App'
import PenIcon from '../../Icon/PenIcon'
import { Modal, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import OrdersResponsible from '../../Components/Employee/OrdersResponsible'
import ResponsibleStages from '../../Components/Employee/ResponsibleStages'
import EmployeeCreationModal from '../../Components/Home/EmployeeCreationModal'
import CreateEmployee from '../../Components/Employee/CreateEmployee'

const ParticularEmployee = () => {
    let { id } = useParams()
    let [pageLoading, setPageLoading] = useState(false)
    let [showModal, setShowModal] = useState()
    let [showOrder, setshowOrders] = useState({
        orders: true,
        stages: true
    })
    let [user, setUser] = useState()
    let getUser = () => {
        axios.get(`${port}/api/user/${id}`).then((response) => {
            setUser(response.data)
            console.log(response.data, "reuser");
            setPageLoading('')
        }).catch((error) => {
            console.log(error);
            setPageLoading('')
        })
    }
    let resetData = () => {
        getUser()
        setShowModal(false)
    }
    let changeImage = (e) => {
        let formData = new FormData()
        setPageLoading('image')
        formData.append('profile', e.target.files[0])
        formData.append('id', id)
        axios.put(`${port}/api/user`, formData).then((response) => {
            console.log(response.data);
            getUser();

        }).catch((error) => {
            console.log(error);
            toast.error('Error Acquired')
        })

    }
    useEffect(() => {
        if (id)
            getUser()
    }, [id])
    return (
        <div>
            <h4 className='text-2xl text-slate-600 ' >Employee Details </h4>
            {
                !user ? <div className='flex min-h-[70vh] ' >
                    <Spinner className='m-auto ' />
                </div> : <>

                    {/* Content 1 profile details */}
                    <main className=' row container mx-auto my-3 ' >


                        <article className=' col-lg-6 mx-auto my-3 ' >
                            <section className=' rounded items-center min-h-[52vh] flex justify-between 
                             bg-white p-3 ' >

                                <div className='relative ' >

                                    {pageLoading == 'image' ?
                                        <div className='w-32 h-32 flex rounded-full bg-slate-200  ' >
                                            <Spinner className='m-auto ' />
                                        </div> : <img src={user.profile_pic ?
                                            user.profile_pic : require('../../Assests/profile.png')}
                                            alt="Profile" className='w-32 h-32 object-cover rounded-full ' />}
                                    {id == sessionStorage.getItem('gebba_user') &&
                                        <label htmlFor='imageup'
                                            className='absolute left-1/2 -bottom-2 cursor-pointer p-[5px] bg-slate-400 rounded-full text-slate-50 w-fit  ' >
                                            <PenIcon />
                                        </label>
                                    }
                                    <input type="file" onChange={changeImage} name="" className='hidden  ' id="imageup" />
                                </div>
                                <Modal className='' centered show={showModal}
                                    onHide={() => setShowModal(false)} >
                                    <CreateEmployee user={user} page='dash' resetData={resetData} />
                                </Modal>
                                <section className=' w-2/3 p-2 min-h-[45vh] flex flex-col justify-around relative ' >
                                    <button onClick={() => setShowModal(true)} className='absolute border-2 rounded-full p-[8px] right-2 top-0' >
                                        <PenIcon size={14} />
                                    </button>

                                    <div>
                                        <label htmlFor="" className='w-20 my-2 fw-semibold text-slate-800 ' > Name  </label>
                                        <input type="text" value={`${user.emp_name}(${user.emp_type}) `}
                                            className='border-b-2 bg-transparent w-full border-opacity-70 border-slate-500 outline-none' disabled />
                                    </div>
                                    <div>
                                        <label htmlFor="" className='w-20 my-2 fw-semibold text-slate-800 ' > Email  </label>
                                        <input type="text" value={user.email}
                                            className='border-b-2 bg-transparent w-full border-opacity-70 border-slate-500 outline-none' disabled />
                                    </div>
                                    <div>
                                        <label htmlFor="" className='w-20 my-2 fw-semibold text-slate-800 ' > Phone  </label>
                                        <input type="text" value={user.phone}
                                            className='border-b-2 bg-transparent w-full border-opacity-70 border-slate-500 outline-none' disabled />
                                    </div>
                                    <div>
                                        <label htmlFor="" className='w-20 my-2 fw-semibold text-slate-800 ' > Status  </label>
                                        <input type="text" value={user.status}
                                            className='border-b-2 bg-transparent w-full border-opacity-70 border-slate-500 outline-none' disabled />
                                    </div>
                                </section>
                            </section>
                        </article>
                        {/* <div className='col-lg-6 ' >

                        </div> */}
                        {/* Col  */}
                        {
                            showOrder.orders && <article className='col-lg-6 my-3 min-h-[10vh] ' >
                                <section className='bg-white h-full w-full rounded ' >
                                    <OrdersResponsible id={id} align={"justify-between "} setshowOrders={setshowOrders} />
                                </section>
                            </article>
                        }
                        {/* Col */}
                        {showOrder.stages && <article className='col-lg-6 my-3 min-h-[10vh] ' >
                            <section className='bg-white h-full w-full rounded ' >
                                <ResponsibleStages id={id} align="justify-between " setshowOrders={setshowOrders} />

                            </section>

                        </article>}

                    </main>
                </>
            }
        </div >
    )
}

export default ParticularEmployee