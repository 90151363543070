import React from 'react'

const OrderIcon = ({ size }) => {
    return (
        <svg width={size ? size : "20"} height={size ? size : "20"} viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1H3C1.89543 1 1 1.89543 1 3V16C1 17.1046 1.89543 18 3 18H13C14.1046 18 15 17.1046 15 16V3C15 1.89543 14.1046 1 13 1Z" stroke="currentColor" stroke-width="2" />
            <path d="M5 6H11M5 10H11M5 14H9" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
        </svg>
    )
}

export default OrderIcon