import React, { useState } from 'react'
import ScannerButton from './ScannerButton'

const InputComponent = ({ value, mandatory, label, limit, size, onchange, name, setobj,
    disable, arry, textarea, type, scan, index, rows }) => {

    return (
        <section className={` my-2 ${size ? size : "col-sm-6  "}  flex justify-between gap-3 `} >
            <div className='flex w-full justify-between border-slate-300 border-2 px-2 h-fit p-[10px]
             rounded-xl items-start ' >
                <label htmlFor="" className=' txtclr text-nowrap' > {label}
                    {mandatory && <span className='text-red-600 mx-1 ' >* </span>}
                </label>


                {!textarea && <input disabled={disable} type={type ? type : 'text'}
                    name={name} value={value} onChange={(e) => {
                        if (type == 'number') {
                            if (limit && e.target.value >= 0 && e.target.value <= limit)
                                onchange(e, index)
                            if (e.target.value >= 0 && !limit)
                                onchange(e, index)
                        }
                        else
                            onchange(e, index)
                    }
                    }
                    className='w-full outline-none bg-transparent px-2' />}
                {
                    textarea &&
                    <textarea name={name} rows={rows ? rows : 4} disabled={disable} value={value} onChange={(e) => onchange(e, index)}
                        className='w-full px-2 outline-none bg-transparent ' >

                    </textarea>
                }</div>
            {scan && <ScannerButton name={name} arry={arry}
                index={index} setobj={setobj} />}
        </section>
    )
}

export default InputComponent