import React, { useContext, useEffect, useState } from 'react'
import { Store } from '../../Context/Storage'
import { useNavigate } from 'react-router'
import EmployeeTable from '../../Components/Tables/EmployeeTable'
import ResponsibleStages from '../../Components/Employee/ResponsibleStages'
import EmployeeDashBoard from './EmployeeDashBoard'
import EmployeeCreationModal from '../../Components/Home/EmployeeCreationModal'

const TotalEmployee = () => {
  let { dasactive, setDasActive, getAllEmp, allemp } = useContext(Store)
  let [filteredData, setFilteredData] = useState()
  useEffect(() => {
    sessionStorage.getItem('status') != 'admin' ?
      setDasActive('assign') :
      setDasActive('emp')                                    

    getAllEmp()
  }, [])
  useEffect(() => {
    if (allemp) {
      setFilteredData(allemp)
      // console.log('hellow');
    }
  }, [allemp])
  let navigate = useNavigate()
  return (
    <div className=' ' >
      {
        sessionStorage.getItem('status') != 'admin' ? <EmployeeDashBoard /> :
          <>
            <section className='flex justify-between items-center ' >

              <h4 className='poppins text-slate-800 text-lg' >All Employees  </h4>


            </section>

            <EmployeeTable data={filteredData} />

          </>
      }
    </div>
  )
}

export default TotalEmployee