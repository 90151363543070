import React from 'react'
import Login from './Login'
import { useNavigate } from 'react-router'

const Protect = ({ Child }) => {
    let user = sessionStorage.getItem('gebba_user')
    console.log(user);

    let navigate = useNavigate()
    return (
        <div>
            {
                user ? <Child /> : <Login />
            }
        </div>
    )
}

export default Protect