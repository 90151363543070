import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { port } from '../../App'
import InputComponent from '../../Components/InputComponent'
import { Modal, Spinner } from 'react-bootstrap'
import MaterialModel from '../../Components/MaterialModel'
import { Store } from '../../Context/Storage'
import { toast } from 'react-toastify'

const StagePage = () => {
    let { id } = useParams()
    let [loading, setloading] = useState()
    let [stageNumberCheckingModel, setStageNumberCheckingModel] = useState({
        show: false,
        number: ''
    })

    let { readableTime } = useContext(Store)
    let handleStageNumberCheck = (e) => {
        let { name, value } = e.target
        setStageNumberCheckingModel((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    let [stage, setStage] = useState({
        next_stage: '',
        prev_stage: '',
        can_add_material: null,
        goodQuality: 0,
        badQuantity: 0,
        prev_stage: '',
        next_stage: '',
        coments: ''
    })
    let handleChange = (e) => {
        let { name, value } = e.target
        setStage((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    let [show, setshow] = useState(false)
    let [materials, setMaterials] = useState()
    let getParticularStage = () => {
        axios.get(`${port}/api/stages/${id}`).then((response) => {
            console.log(response.data, "stage");
            setStage(response.data)
            setMaterials(response.data.materials_prepared_by)
        }).catch((error) => {
            console.log(error);
        })
    }
    let updateStage = () => {
        setloading('update')
        axios.put(`${port}/api/stage`, { ...stage, id: id }).then((response) => {
            console.log(response.data);
            toast.success('Updated successfully')
            getParticularStage()
            setloading(false)
        }).catch((error) => {
            console.log(error);
            setloading(false)
        })
    }
    let passToNext = () => {
        setloading('pass')

        axios.put(`${port}/api/stage`,
            {
                ...stage.next_stage,
                id: stage.next_stage._id,
                can_add_material: true
            }).then((response) => {
                console.log(response.data);
                toast.success('Passed to the Next stage successfully')
                getParticularStage()
                setloading(false)
                setStageNumberCheckingModel({
                    number: "",
                    show: false
                })
            }).catch((error) => {
                console.log(error);
                setloading(false)
            })
    }

    useEffect(() => {
        if (id)
            getParticularStage()
    }, [id])
    return (
        <div>

            {stage ? <>
                {stage.can_add_material ?
                    <button onClick={() => setshow(stage.orderId)} className='ms-auto flex my-3 bg-blue-600 px-2  rounded text-sm p-1 text-white ' >
                        Scan Material
                    </button> :
                    <div className='ms-auto flex my-3 w-fit ' >
                        <p>Previous Stage not completed </p>

                    </div>
                }
                {console.log(stage.orderId && stage.orderId.incharge && stage.orderId.incharge.find((obj) => obj.active) && stage.orderId.incharge.find((obj) => obj.active).employee &&
                    stage.orderId.incharge.find((obj) => obj.active).employee._id, "incharge")
                }
                <MaterialModel id={id} show={show}
                    activeIncharge={stage.orderId && stage.orderId.incharge && stage.orderId.incharge.find((obj) => obj.active)
                        && stage.orderId.incharge.find((obj) => obj.active).employee &&
                        stage.orderId.incharge.find((obj) => obj.active).employee._id}
                    getParticularStage={getParticularStage}
                    setshow={setshow} />
                <h4 className=' text-slate-500 text-xl ' > Order Details </h4>
                <main className='row bg-white container mx-auto rounded py-3 ' >
                    <section className='col-lg-6 flex flex-col gap-2 ' >
                        <InputComponent size="col-12 " label="Order Id : " disable value={stage.orderId && stage.orderId.orderId} />
                        <InputComponent size="col-12 " label="Active Incharge : " disable
                            value={stage.orderId && stage.orderId.incharge && stage.orderId.incharge.find((obj) => obj.active)
                                && stage.orderId.incharge.find((obj) => obj.active).employee &&
                                stage.orderId.incharge.find((obj) => obj.active).employee.emp_name} />
                        <InputComponent size="col-12 " label="Quantity : " disable value={stage.orderId && stage.orderId.quantity} />

                    </section>
                    <section className='col-lg-6 ' >

                        <InputComponent size="col-12 " label="Order Description : " textarea disable value={stage.orderId && stage.orderId.description} />

                    </section>
                </main>
                <section className='flex flex-wrap gap-3 items-center ' >
                    <h5 className='text-slate-500 text-xl my-3 ' >Stage Details </h5>
                    {stage && stage.prev_stage && stage.prev_stage.goodQuality && <span className='text-sm text-blue-800 ' >
                        (Previous stage passed {stage.prev_stage.goodQuality} products ) </span>}
                </section>

                <main className='row bg-white rounded container mx-auto py-3' >
                    <section className='row container-fluid mx-auto px-0 col-lg-8 ' >

                        <InputComponent size="col-sm-6 " label="Confirm No : " disable value={stage.confimation_no} />
                        <InputComponent size="col-sm-6" label="Stage Name : " disable value={stage.stage_name} />
                        <InputComponent size="col-sm-6" label="Material Scanned : " disable value={materials && materials.length} />
                        <InputComponent size='col-sm-6' label="Good items Quantity :" value={stage.goodQuality} name="goodQuality" onchange={handleChange}
                            type="number"
                            limit={(stage && stage.prev_stage && stage.prev_stage.goodQuality)
                                ? stage.prev_stage.goodQuality - (stage.badQuantity ? Number(stage.badQuantity) : 0)


                                : (stage.orderId && stage.orderId.quantity && stage.badQuantity) ?
                                    Number(stage.orderId.quantity) - Number(stage.badQuantity) :
                                    stage.orderId && stage.orderId.quantity} />
                        <InputComponent size="col-sm-12" rows={2} label="Leave a Comments on the stage : " value={stage.coments} name="coments" onchange={handleChange}
                            textarea />
                    </section>
                    <section className=' col-lg-4 flex flex-col gap-1 ' >

                        <InputComponent size="col-12" label="Quality Issue Quantity" value={stage.badQuantity} name="badQuantity" onchange={handleChange}
                            type="number" limit={
                                (stage && stage.prev_stage && stage.prev_stage.goodQuality) ?
                                    stage.prev_stage.goodQuality - (stage.goodQuality ? Number(stage.goodQuality) : 0) :

                                    (stage.orderId && stage.orderId.quantity && stage.goodQuality) ?
                                        Number(stage.orderId.quantity) - Number(stage.goodQuality) :
                                        stage.orderId && stage.orderId.quantity} />

                        <InputComponent size="col-12" rows={5} label="Stage Description: " textarea disable value={stage.stage_description} />

                    </section>
                </main>

                <h5 className='text-slate-500 my-3 ' > Materials Prepared </h5>
                <main className='row container mx-auto bg-white rounded ' >
                    <section className='col-lg-6 my-3 ' >

                        <div className='table-responsive border-2 border-slate-300 tablebg rounded-xl  ' >
                            <table className='w-full ' >
                                <tr>
                                    <th>Machine </th>
                                    <th>Material </th>
                                    <th>Part Number </th>
                                    <th>Employee </th>
                                    <th>Incharge </th>
                                    <th>Scanned Time </th>
                                </tr>
                                {
                                    materials && materials.slice(0, Math.round(materials.length / 2)).map((obj) => (
                                        <tr>
                                            <td>{obj.machinery} </td>
                                            <td>{obj.material} </td>
                                            <td>{obj.part_number} </td>
                                            <td>{obj.employee && obj.employee.emp_name} </td>
                                            <td>{obj.incharge && obj.incharge.emp_name} </td>
                                            <td>{obj.scannedTime && readableTime(obj.scannedTime)} </td>
                                        </tr>
                                    ))
                                }
                            </table>

                        </div>
                    </section>
                    <section className='col-lg-6 my-3 ' >

                        <div className='table-responsive border-2 border-slate-300 tablebg rounded-xl  ' >
                            <table className='w-full ' >
                                <tr>
                                    <th>Machine </th>
                                    <th>Material </th>
                                    <th>Part Number </th>
                                    <th>Employee </th>
                                    <th>Incharge </th>

                                    <th>Scanned Time </th>
                                </tr>
                                {
                                    materials && materials.slice(Math.round(materials.length / 2)).map((obj) => (
                                        <tr>
                                            <td>{obj.machinery} </td>
                                            <td>{obj.material} </td>
                                            <td>{obj.part_number} </td>
                                            <td>{obj.employee && obj.employee.emp_name} </td>
                                            <td>{obj.incharge && obj.incharge.emp_name} </td>
                                            <td>{obj.scannedTime && readableTime(obj.scannedTime)} </td>
                                        </tr>
                                    ))
                                }
                            </table>

                        </div>

                    </section>
                    {stage.can_add_material && <button disabled={loading == 'update'} onClick={updateStage} className='w-fit bg-blue-600 my-3 text-white rounded h-fit p-2 ' >
                        {loading == 'update' ? "Loading..." : "Update"}
                    </button>}
                    {stage.next_stage && !stage.next_stage.can_add_material &&
                        <button disabled={loading == 'pass'} onClick={() => setStageNumberCheckingModel((prev) => ({
                            ...prev,
                            show: true
                        }))} className=' w-fit ms-auto my-3 bg-green-600 text-white p-2 rounded  ' >
                            {loading == 'pass' ? 'Loading...' : " Pass to next stage"}
                        </button>
                    }
                </main>
                {/* Verification */}
                {stage && <Modal centered className='' show={stageNumberCheckingModel.show} onHide={() => setStageNumberCheckingModel({
                    show: false,
                    number: ''
                })}>
                    <Modal.Header closeButton>
                        Pass Checking
                    </Modal.Header>
                    <Modal.Body>
                        <InputComponent size='col-12' mandatory value={stageNumberCheckingModel.number} onchange={handleStageNumberCheck} name="number"
                            setobj={setStageNumberCheckingModel} scan label="Scan Confirmation NO :" />
                        <button onClick={() => {
                            if (stageNumberCheckingModel.number == stage.confimation_no)
                                passToNext()
                            else
                                toast.warning(`Stage Confirmation number mismatched ,${stage.confimation_no},${stageNumberCheckingModel.number}`)

                        }} disabled={loading == 'pass'} className='my-3 flex justify-end text-sm bg-blue-600 text-white rounded p-2 px-3 ' >
                            {loading == 'pass' ? 'Loading...' : "Submit"}
                        </button>
                    </Modal.Body>
                </Modal>}

            </> :
                <div className='h-[60vh] flex ' >
                    <Spinner className='m-auto ' />
                </div>}

        </div>
    )
}

export default StagePage