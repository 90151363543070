import React, { useContext } from 'react'
import DashboardIcon from '../Icon/DashboardIcon'
import { Store } from '../Context/Storage'
import { useNavigate } from 'react-router'
import OrderIcon from '../Icon/OrderIcon'
import LogOutIcon from '../Icon/LogOutIcon'
import JournalIcon from '../Icon/JournalIcon'
import MachineIcon from '../Icon/MachineIcon'
import MachineryIcon from '../Icon/MachineryIcon'

const SideBar = ({ setshow }) => {
    let { active, setactive } = useContext(Store)
    let navigate = useNavigate()
    return (
        <div>
            <main className=' h-[100vh] py-4 sidebarbg'  >
                <img src={require('../Assests/GebaLogo.webp')} alt="Logo image" className='flex mx-auto ' />
                <hr className='border-2 border-slate-100 opacity-50 mt-3 ' />
                <section className=' w-full h-[85vh] flex flex-col justify-between py-3 ps-3 ' >
                    <article>

                        <div className='my-2  px-0 ' >
                            <button onClick={() => {
                                navigate('/');
                                if (setshow) setshow(false)
                            }} className={` ${active == 'dash' ? 'text-slate-50 ' : ' text-slate-300 hover:text-slate-50 '}
                        duration-300  flex items-center gap-2 `} >
                                <DashboardIcon size={22} />
                                Dashboard
                            </button>
                        </div>
                        {sessionStorage.getItem('status') != 'employee' && <div className='my-3  ' >
                            <button onClick={() => {
                                navigate('/orderCreation');
                                if (setshow) setshow(false)
                            }}
                                className={` ${active == 'order' ? 'text-slate-50 ' : ' text-slate-300 hover:text-slate-50 '}
                        duration-300  flex items-center gap-2 `} >
                                <OrderIcon size={14} />
                                Order Creation
                            </button>
                        </div>}
                        {sessionStorage.getItem('status') != 'employee' && <div className='my-3  ' >
                            <button onClick={() => {
                                navigate('/stageHistory');
                                if (setshow) setshow(false)
                            }}
                                className={` ${active == 'stagehistory' ? 'text-slate-50 ' : ' text-slate-300 hover:text-slate-50 '}
                        duration-300  flex items-center gap-2 `} >
                                <JournalIcon size={16} />
                                Stage History
                            </button>
                        </div>}
                        {sessionStorage.getItem('status') == 'admin' && <div className='my-3  ' >
                            <button onClick={() => {
                                navigate('/machines');
                                if (setshow) setshow(false)
                            }}
                                className={` ${active == 'machine' ? 'text-slate-50 ' : ' text-slate-300 hover:text-slate-50 '}
                        duration-300  flex items-center gap-2 `} >
                                <MachineryIcon size={20} />
                                Machines
                            </button>
                        </div>}
                        {sessionStorage.getItem('status') == 'admin' && <div className='my-3  ' >
                            <button onClick={() => {
                                navigate('/stageName');
                                if (setshow) setshow(false)
                            }}
                                className={` ${active == 'machine' ? 'text-slate-50 ' : ' text-slate-300 hover:text-slate-50 '}
                        duration-300  flex items-center gap-2 `} >
                                <MachineIcon size={14} />
                                Stage Name
                            </button>
                        </div>}
                    </article>

                    <div className='my-3  ' >
                        <button onClick={() => {
                            navigate('/');
                            sessionStorage.removeItem('gebba_user')
                            if (setshow) setshow(false)
                        }}
                            className={` ${active == 'log' ? 'text-slate-50 ' : ' text-slate-300 hover:text-slate-50 '}
                        duration-300  flex items-center gap-2 `} >
                            <LogOutIcon />
                            Log Out
                        </button>
                    </div>
                </section>

            </main>
        </div>
    )
}

export default SideBar