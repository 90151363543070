import React, { useContext, useEffect, useState } from 'react'
import ResponsibleStages from '../../Components/Employee/ResponsibleStages'
import { Store } from '../../Context/Storage'
import { useNavigate } from 'react-router'
import StageTable from '../../Components/Tables/StageTable'
import axios from 'axios'
import { port } from '../../App'
import { Spinner } from 'react-bootstrap'

const StageHistory = () => {
    let { active, setactive } = useContext(Store)
    let navigate = useNavigate()
    let [loading, setloading] = useState(false)
    let [historyStage, setHistoryStage] = useState()
    let getHistory = () => {
        setloading(true)
        axios.get(`${port}/api/stages`).then((response) => {
            setHistoryStage(response.data)
            console.log("stage", response.data);
            setloading(false)
        }).catch((error) => {
            console.log(error);
            setloading(false)
        })
    }
    useEffect(() => {
        setactive('stagehistory')
        getHistory()
    }, [])
    return (
        <div className='p-3 ' >
            <h4 className=' ' > Stage History </h4>
            {loading ?
                <div className='min-h-[70vh] flex ' >
                    <Spinner className='m-auto '/>
                </div> :
                <StageTable data={historyStage} />
            }

        </div>
    )
}

export default StageHistory