import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { port } from '../../App'
import StageTable from '../Tables/StageTable'

const ResponsibleStages = ({ id, align, setshowOrders }) => {
    let [resStages, setResStages] = useState()
    let getResponsibleStage = () => {
        axios.get(`${port}/api/stage?empId=${id}`).then((response) => {
            setResStages(response.data);
            console.log(response.data, "resStages ");
            if (response.data.length <= 0)
                setshowOrders((prev) => ({
                    ...prev,
                    stages: false
                }))
        }).catch((error) => {
            console.log(error);
        })
    }
    useEffect(() => {
        getResponsibleStage()
    }, [])

    return (
        <div className='p-3 ' >
            <h4 className=' ' >  Responsible Stages </h4>
            <StageTable data={resStages} align={align} size="h-[30vh] " />

        </div>
    )
}

export default ResponsibleStages