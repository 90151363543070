import React from 'react';
import * as XLSX from 'xlsx';


const ExcelDownload = ({ data, type, name }) => {
    let convertToExcel = () => {
        let exportData = [...data].reverse()
        // Create a new workbook
        const workbook = XLSX.utils.book_new();
        let worksheet
        if (type == undefined || type != 'notes') {
            worksheet = XLSX.utils.json_to_sheet(exportData);
        }
        if (type == 'notes') {
            worksheet = XLSX.utils.json_to_sheet(data);
        }
        const header = ["ID", "Name", "Assigned to", "Completed Date"];
        const headerStyle = {
            font: { bold: true, color: { rgb: "FF0000" } }, // Red color
            fill: { fgColor: { rgb: "FFFF00" } } // Yellow fill
        };
        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, name);
        // Generate a buffer and download the file
        XLSX.writeFile(workbook, `${name}.xlsx`);
    }
    return (
        <div>
            <button onClick={convertToExcel}
                className='bg-blue-600 text-white p-2 rounded text-sm ' >
                Download
            </button>
        </div>
    )
}

export default ExcelDownload