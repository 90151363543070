import React, { useContext, useEffect } from 'react'
import { Store } from '../../Context/Storage'
import DashBoardContent from '../../Pages/DashBoardContent'

const EmployeeProtect = ({ Child, emp }) => {
    let { user, getUser } = useContext(Store)
    useEffect(() => {
        if (!user)
            getUser()
    }, [])
    return (
        <div>
            {user && user.emp_type != emp ? <Child> </Child>

                : <DashBoardContent />
            }
        </div>
    )
}

export default EmployeeProtect