import axios from 'axios'
import React, { createContext, useEffect, useState } from 'react'
import { port } from '../App'
import CryptoJS from 'crypto-js';

export const Store = createContext()
const Storage = ({ children }) => {
    let [active, setactive] = useState()
    let [dasactive, setDasActive] = useState()
    let [user, setUser] = useState()
    let [allorders, setAllOrders] = useState()
    let [allemp, setAllEmp] = useState()
    let [allMachine, setAllMachine] = useState()
    let [allStages, setAllStages] = useState()
    let getAllStageNames = () => {
        axios.get(`${port}/api/stageName`).then((response) => {
            console.log(response.data);
            setAllStages(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }
    let getUser = () => {
        let id = (sessionStorage.getItem('gebba_user'))
        if (id)
            axios.get(`${port}/api/user/${id}`).then((response) => {
                setUser(response.data)
                console.log(response.data, "user");
            }).catch((error) => {
                console.log(error);
            })
    }
    let getAllMachine = () => {
        axios.get(`${port}/api/machinery`).then((response) => {
            setAllMachine(response.data)
            console.log(response.data, "machine");
        }).catch((error) => {
            console.log(error);
        })
    }
    function getProperDate(date) {
        const now = new Date(date);
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    let getAllEmp = () => {
        axios.get(`${port}/api/user`).then((response) => {
            setAllEmp(response.data)
            console.log(response.data, "allemp");
        }).catch((error) => {
            console.log(error);
        })
    }
    let getAllOrders = () => {
        axios.get(`${port}/api/order`).then((response) => {
            console.log(response.data, 'orders');
            setAllOrders(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }

    let readableTime = (times) => {
        const date = new Date(times);
        // Format the date and time for IST (Indian Standard Time)
        const day = date.toLocaleString('en-IN', { day: 'numeric', timeZone: 'Asia/Kolkata' });
        const month = date.toLocaleString('en-IN', { month: 'numeric', timeZone: 'Asia/Kolkata' });
        const year = date.toLocaleString('en-IN', { year: '2-digit', timeZone: 'Asia/Kolkata' });
        const time = date.toLocaleString('en-IN', { hour: 'numeric', minute: '2-digit', hour12: true, timeZone: 'Asia/Kolkata' });

        // Combine into desired format
        const readableDate = `${day}-${month}-${year}`;
        return readableDate;
    }
    useEffect(() => {
        getAllMachine()
        getAllStageNames()
        if (!user)
            getUser()
    }, [])
    let encryptWord = (word) => {
        return CryptoJS.AES.encrypt(word, 'jerold').toString();
    }
    let decryptWord = (word) => {
        const bytes = CryptoJS.AES.decrypt(word, 'jerold')
        return bytes.toString(CryptoJS.enc.Utf8);
    }
    let value = {
        getProperDate, getAllMachine, allMachine, encryptWord, decryptWord,
        allStages, setAllStages, getAllStageNames,
        active, setactive, getUser, user, dasactive, readableTime,
        setDasActive, getAllEmp, allemp, getAllOrders, allorders
    }

    return (
        <Store.Provider value={value} >
            {children}
        </Store.Provider>
    )
}

export default Storage