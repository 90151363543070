import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import CreateEmployee from '../Employee/CreateEmployee'

const EmployeeCreationModal = () => {
    let [show, setshow] = useState(false)
    return (
        <div>
            <button onClick={() => setshow(true)} className=' p-2 rounded text-sm text-white bg-blue-600 ' >
                Create employee
            </button>
            <Modal show={show} centered onHide={() => setshow(false)} >
                <Modal.Body>
                    <CreateEmployee onHide={setshow} page="dash" />
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default EmployeeCreationModal