import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { port } from '../../App'
import { toast } from 'react-toastify'
import InfoComponent from '../InfoComponent'
import { Store } from '../../Context/Storage'

const CreateEmployee = ({ page, onHide, user, id, resetData }) => {
    let navigate = useNavigate()
    let { getAllEmp } = useContext(Store)
    let [errormsg, setErrorMsg] = useState()
    let [progressBar, setProgressBar] = useState()
    let [loading, setloading] = useState(false)
    let [empObj, setEmpObj] = useState({
        emp_type: '',
        emp_name: '',
        email: '',
        phone: '',
        password: '',
        otp: '',
        empId: '',
        profile_pic: null
    })
    let [recievedOtp, setReceivedOtp] = useState()
    let handleEmpObj = (e) => {
        let { name, value, files } = e.target
        if (name == 'profile_pic') {
            value = files[0]
        }
        setEmpObj((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    let [passwordShow, setShowPassword] = useState(false)
    let signup = () => {
        alert("Calling")
    }
    let reset = () => {
        getAllEmp()
        setEmpObj({
            emp_type: '',
            emp_name: '',
            email: '',
            phone: '',
            password: '',
            empId: '',
            otp: '',
            profile_pic: null
        })
        setProgressBar('')
    }
    useEffect(() => {
        if (user)
            setEmpObj(user)
    }, [user])
    let createEmployee = () => {
        setErrorMsg('')
        let formData = new FormData()
        formData.append('emp_type', empObj.emp_type);
        formData.append('emp_name', empObj.emp_name);
        formData.append('email', empObj.email);
        formData.append('phone', empObj.phone);
        formData.append('password', empObj.password);
        formData.append('empId', empObj.empId);

        // formData.append('otp', empObj.otp);
        // Append the profile picture if it's selected
        if (empObj.profile_pic) {
            formData.append('profile', empObj.profile_pic);
        }

        if (!recievedOtp || empObj.otp == recievedOtp) {

            if (empObj.email != '' && empObj.emp_name != '' && empObj.password != ''
                && empObj.phone != '' && empObj.emp_type != ''
            ) {
                setloading("create")
                axios.post(`${port}/api/user`, formData).then((response) => {
                    console.log(response.data);
                    toast.success("User added")
                    setloading(false)
                    if (onHide)
                        onHide(false)
                    reset()
                    setReceivedOtp('')
                }).catch((error) => {
                    console.log(error);
                    setloading(false);
                    toast.error('error acquired')
                    if (typeof error.response.data == "string")
                        setErrorMsg(error.response.data)
                })
            }
            else
                toast.warning('Enter the required fields.')
        }
        else
            toast.warning('OTP is mismatched')

    }
    let updateEmployee = () => {
        setloading('update')
        delete empObj.password
        axios.put(`${port}/api/user`, {
            ...empObj,
            id: empObj._id
        }).then((response) => {
            console.log(response.data);
            toast.success('Employee updated success fully')
            resetData();
            setloading(false)
        }).catch((error) => {
            console.log(error);
            setloading(false)
            toast.error('Error Acquired')
        })

    }
    let getOtp = () => {
        if (empObj.email != '' && empObj.emp_name != '' &&
            empObj.password != '' && empObj.empId
            && empObj.phone != '' && empObj.emp_type != ''
        ) {
            if (!passwordChecking(empObj.password))
                return toast.warning('Password not strong enough')
            setloading(true)
            axios.get(`${port}/api/adminAuth`, empObj).then((response) => {
                toast.success('Otp has been sended to the Admin Mail Id')
                setReceivedOtp(response.data.otp)
                console.log(response.data);
                setloading(false)
            }).catch((error) => {
                console.log(error);
                setloading(false)
            })
        }
        else
            toast.warning('Enter all the required fields')
    }
    let passwordChecking = (value) => {

        let sum = 0
        let capitalValue = /[A-Z]/
        let numberValue = /[0-9]/
        let smalllValue = /[a-z]/
        let splValue = /[!@#$%^*_=+?]/
        if (capitalValue.test(value))
            sum += 20
        if (numberValue.test(value))
            sum += 20
        if (smalllValue.test(value))
            sum += 20
        if (splValue.test(value))
            sum += 20
        if (value.length >= 8)
            sum += 20

        if (sum == 100)
            setProgressBar('w-[100%] bg-green-400 ')
        else if (sum >= 80)
            setProgressBar('w-[80%] bg-blue-400 ')
        else if (sum >= 60)
            setProgressBar('w-[60%] bg-yellow-400 ')
        else if (sum >= 40)
            setProgressBar('w-[40%] bg-orange-400 ')
        else if (sum >= 0)
            setProgressBar('w-[20%] bg-red-400 ')
        console.log(sum);
        return sum == 100 ? true : false
    }
    return (
        <div>
            <section className={`m-auto bg-white col-sm-8 col-lg-5 
            ${page && 'col-lg-12'} p-2 px-0
             rounded-xl border-2  `} >
                <h4 className='text-center text-3xl my-2' >{user ? 'Update Employee' : page ? 'Create Employee' : "Employee creation"} </h4>
                <form action="" onSubmit={signup} className='text-start max-h-[80vh] formscroll overflow-y-scroll px-4 ' >
                    <div className='text-start my-2 ' >
                        <p className='mb-1 fw-semibold ' >  Employee name <span className='text-red-600 ' >* </span>  </p>
                        <input type="text" name='emp_name' value={empObj.emp_name} onChange={handleEmpObj}
                            className='mt-2 w-full p-2 rounded-lg outline-none border-2  ' />
                    </div>
                    <div className='text-start my-2 ' >
                        <p className='mb-1 fw-semibold ' >  Employee ID <span className='text-red-600 ' >* </span>  </p>
                        <input type="text" name='empId' value={empObj.empId} onChange={handleEmpObj}
                            className='mt-2 w-full p-2 rounded-lg outline-none border-2  ' />
                    </div>
                    <div className='text-start my-2 ' >
                        <p className='mb-1 fw-semibold ' >  Email <span className='text-red-600 ' >* </span> </p>
                        <input type="email" name='email' value={empObj.email} onChange={handleEmpObj}
                            className='mt-2 w-full p-2 rounded-lg outline-none border-2  ' />
                    </div>
                    <div className='text-start my-2 ' >
                        <p className='mb-1 fw-semibold ' >  Phone <span className='text-red-600 ' >* </span> </p>
                        <input type="number" name='phone' value={empObj.phone} onChange={handleEmpObj}
                            className='mt-2 w-full p-2 rounded-lg outline-none border-2  ' />
                    </div>
                    <div className='text-start my-2 ' >
                        <p className='mb-1 fw-semibold ' >  Employee Role <span className='text-red-600 ' >* </span> </p>
                        <select type="number" name='emp_type' value={empObj.emp_type} onChange={handleEmpObj}
                            className='mt-2 w-full p-2 rounded-lg outline-none border-2  ' >
                            <option value="">Select</option>
                            <option value="admin">Admin</option>
                            <option value="incharge">Row Incharge</option>
                            <option value="employee">Employee</option>

                        </select>
                    </div>
                    {
                        user &&
                        <div className='my-2 ' >
                            <p className='mb-1 fw-semibold ' >  Active status </p>
                            <select name="status" id="" value={empObj.status} onChange={handleEmpObj}
                                className='mt-2 w-full p-2 rounded-lg outline-none border-2  ' >
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </select>
                        </div>
                    }
                    {!user && <div className='my-2 ' >
                        <article className='flex justify-between gap-3   ' >
                            <p className='mb-1 fw-semibold ' >Password <span className='text-red-600 ' >* </span>


                            </p>
                            <button type='button' onClick={() => setShowPassword(!passwordShow)} className=' ' >
                                {passwordShow ? "Show" : "Hide"}
                            </button>
                        </article>

                        <input type={`${passwordShow ? 'password' : 'text'}`}
                            name='password' value={empObj.password} onChange={(e) => { handleEmpObj(e); passwordChecking(e.target.value) }}
                            className='mt-2 w-full p-2 rounded-lg outline-none border-2  ' />
                        {/* Progress bar */}
                        <section className='flex gap-2 items-center relative  text-xs my-4 ' >
                            Password Strength
                            <span className=' -top-3 absolute left-1/4 cursor-pointer ' >
                                <InfoComponent content="Password should have 8 characters with least of 1 Special case , 1 Numeric, 1 Upper case, 1 Lower case" size={12} />
                            </span>
                            <div className='w-24 rounded-full h-[12px] border-2 relative ' >
                                <div className={`${progressBar ? progressBar : " w-[20%] bg-red-300 "}top-0 left-0 duration-300 h-[9px] rounded-full  `} >

                                </div>

                            </div>
                        </section>
                    </div>}
                    {!user && <div className='text-start my-2 ' >
                        <p className='mb-1 fw-semibold ' >  Profile pic  </p>
                        <input type="file" accept='image/*' name='profile_pic' onChange={handleEmpObj}
                            className='mt-2 w-full p-2 rounded-lg outline-none border-2  ' />
                    </div>}
                    {recievedOtp && <div className='text-start my-2 ' >
                        <p className='mb-1 fw-semibold ' > OTP <span className='text-red-600 ' >* </span> </p>
                        <input type="number" name='otp' value={empObj.otp} onChange={handleEmpObj}
                            className='mt-2 w-full p-2 rounded-lg outline-none border-2  ' />
                    </div>}
                    <p className='h-[25px] text-center my-2 text-red-600 '  >{errormsg}  </p>
                    {!recievedOtp && !page &&
                        <button disabled={loading} onClick={getOtp} type='button'
                            className='w-full rounded-full text-white btngrd p-2' >
                            {loading ? 'Loading..' : "Get OTP"}
                        </button>}
                    {(recievedOtp || page) && !user &&
                        <button type='button' disabled={loading == 'create'} onClick={createEmployee}
                            className='w-full rounded-full text-white btngrd p-3' >
                            {loading == 'create' ? "Loading..." : "Create Employee"}
                        </button>}
                    {user &&
                        <button type='button' disabled={loading == 'update'} onClick={updateEmployee}
                            className='w-full rounded-full text-white btngrd p-3' >
                            {loading == 'update' ? "Loading..." : "Update Employee"}
                        </button>}

                </form>
                <article className='flex justify-between my-2 ' >
                    {/* <button className='my-2 text-center ' >
                                Forgot Password ?
                            </button> */}
                    {!page &&
                        <button onClick={() => navigate('/login')} className='ms-auto me-4 my-2 ' >
                            Log In
                        </button>
                    }
                </article>
            </section>
        </div>
    )
}

export default CreateEmployee