import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { port } from '../../App'
import OrderTables from '../Tables/OrderTables'
import { Store } from '../../Context/Storage'

const OrdersResponsible = ({ id, setshowOrders,align }) => {
    let [inchargeOrders, setInchargeOrders] = useState()
    let { setDasActive } = useContext(Store)
    useEffect(() => {
        setDasActive('incharge')
    }, [])
    let getInchargeOrders = () => {
        console.log(`${port}/api/inchargeOrders/${id ? id : sessionStorage.getItem('gebba_user')}`);

        axios.get(`${port}/api/inchargeOrders/${id ? id : sessionStorage.getItem('gebba_user')}`).then((response) => {
            console.log(response.data, "resorders");
            setInchargeOrders(response.data)

            if (setshowOrders != null && response.data.length <= 0)
                setshowOrders((prev) => ({
                    ...prev,
                    orders: false
                }))
        }).catch((error) => {
            console.log(error);
        })
    }
    useEffect(() => {
        getInchargeOrders()
    }, [id])
    return (
        <div className='p-3 ' >
            Responsible Orders
            {
                inchargeOrders && inchargeOrders.length > 0 ?
                    <OrderTables data={inchargeOrders} align={align} size="h-[30vh]" /> :
                    <div className=' ' >

                    </div>
            }

        </div>
    )
}

export default OrdersResponsible