import React from 'react'

const MachineryIcon = ({ size }) => {
    return (
        <svg width={size ? size : '24'}
            height={size ? size : '24'}
            fill='currentColor'
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 55.634 55.634"
            style={{ enableBackground: 'new 0 0 55.634 55.634' }}
        >
            <g>
                <g>
                    <path d="M36.583,37.243c0.129-1.356,0.09-2.707-0.106-4.026l3.049-1.359c-0.288-1.477-0.741-2.934-1.369-4.344
                    c-0.628-1.409-1.408-2.72-2.312-3.923l-3.051,1.358c-0.849-1.027-1.827-1.959-2.921-2.771l1.196-3.116
                    c-1.249-0.84-2.599-1.55-4.04-2.103c-1.435-0.551-2.909-0.932-4.408-1.143l-1.197,3.119c-1.356-0.129-2.707-0.09-4.026,0.106
                    l-1.351-3.034c-3.018,0.593-5.838,1.873-8.259,3.702l1.342,3.014c-1.029,0.849-1.961,1.828-2.773,2.921l-3.108-1.192
                    c-0.848,1.246-1.559,2.597-2.113,4.037C0.587,29.929,0.211,31.407,0,32.896l3.116,1.196c-0.129,1.356-0.09,2.707,0.106,4.026
                    l-3.049,1.358c0.288,1.478,0.741,2.934,1.369,4.344c0.629,1.41,1.408,2.72,2.313,3.923l3.049-1.358
                    c0.849,1.028,1.827,1.96,2.922,2.772L8.63,52.275c1.249,0.841,2.599,1.55,4.04,2.103c1.435,0.551,2.909,0.932,4.408,1.143
                    l1.197-3.119c1.356,0.128,2.707,0.089,4.026-0.107l1.351,3.033c3.018-0.593,5.84-1.873,8.26-3.702l-1.343-3.014
                    c1.028-0.849,1.961-1.827,2.773-2.921l3.106,1.192c0.848-1.246,1.559-2.597,2.113-4.036c0.553-1.44,0.928-2.919,1.139-4.409
                    L36.583,37.243z M28.3,38.91c-1.791,4.668-7.026,6.998-11.695,5.207c-4.663-1.79-6.996-7.024-5.206-11.691
                    c1.791-4.667,7.026-6.997,11.692-5.208C27.758,29.008,30.09,34.244,28.3,38.91z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M53.465,12.395c0-0.604-0.062-1.195-0.168-1.769l2.337-1.35c-0.566-2.152-1.69-4.079-3.227-5.601l-2.343,1.353
                    c-0.894-0.766-1.93-1.366-3.063-1.766V0.559c-1.032-0.283-2.113-0.445-3.232-0.445c-1.119,0-2.199,0.164-3.232,0.445v2.704
                    c-1.133,0.4-2.168,1.001-3.063,1.766L35.13,3.676c-1.535,1.522-2.66,3.449-3.227,5.601l2.338,1.35
                    c-0.106,0.575-0.169,1.164-0.169,1.769s0.063,1.195,0.169,1.769l-2.338,1.35c0.567,2.152,1.692,4.08,3.227,5.601l2.343-1.353
                    c0.895,0.766,1.93,1.366,3.063,1.766v2.704c1.033,0.282,2.113,0.445,3.232,0.445s2.201-0.164,3.232-0.445v-2.704
                    c1.133-0.4,2.169-1.001,3.063-1.766l2.343,1.353c1.537-1.522,2.661-3.449,3.227-5.601l-2.337-1.35
                    C53.403,13.589,53.465,13,53.465,12.395z M43.77,16.921c-2.5,0-4.527-2.026-4.527-4.525S41.27,7.87,43.77,7.87
                    c2.497,0,4.524,2.026,4.524,4.525S46.267,16.921,43.77,16.921z"/>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>


    )
}

export default MachineryIcon